import React from "react";
import TermsComponent from "../../components/TermsComponent";
import ScrollToTop from "../../components/ScrollToTop";

export default function Terms() {
  const list1 = [
    "Participants are allowed to withdraw a week before classes start, and this is only acceptable via email: training@schull.io",
    "Transfer of participants slot to any other person, be it family or friends, is not allowed.",
    "Due to unforeseen circumstances, participants that can't continue in the present cohort will be allowed to continue from where they stopped in the next cohort; This option is exclusive to participants that made the one-time payment plan option and provided detailed excuses with approval for abstention.",
  ];
  const list2 = [
    "Participants that choose the installmental payment option will have to make payment based on the stated out processes, In instances where a participant is lagging behind in his/her payment, access to the LMS will be restricted until payment is made.",
    "For two installmental payment, If payment is not made after one week of the due date, the participant will face the risk of been pulled out from the program with no refund.",
    " For the 5 installment, If participants misses a month, then the participants will be fully dropped from the program with no refund",
  ];
  const list3 = [
    "Unfortunately, there is no refund 5 days before classes start as by then, we already created a dedicated Learning Management System (Schullex LMS) for every talent.",
    "Any refund to be made five days before the commencement of the program will be done so following how the payment was initially made.",
    "Other refunds will be granted based on agreement by management on rare and unforeseen occasions.",
  ];
  const list4 = [
    "Schullers should endeavor to attend class as much as possible and pay attention in class. If you have reasons to be absent from class, send in an email notification to the administrator training@schull.io employable and provide a platform that elevates you for possible employment. We will work with you to build a great portfolio that will give you the confidence to apply to your dream job through our Cloudpaddie platform.",
    "Any participant that choose to disrespect their follow participant or Tutors will be subject to expulsion from the program after due investigation",
    "Talents are advised to invest in reliable internet as we won't be held responsible if you cannot connect due to a poor network.",
  ];

  return (
    <>
      <ScrollToTop />
      <section className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[140px] pl-[20px] pr-[40px] py-[100px]">
        <TermsComponent title="Terms and Conditions" list={list1} />
        <TermsComponent title="Payment Terms" list={list2} />
        <TermsComponent title="Refund Policy" list={list3} />
        <TermsComponent title="Other" list={list4} />
      </section>
    </>
  );
}
