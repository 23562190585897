import React from "react";
import PaypalCheckout from "./PaypalCheckout";

function PriceCard({ cost, onOptionClick, isDollar }) {
	return (
		<div
			key={cost.id}
			className={
				cost.border
					? "bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] rounded p-[1px]"
					: ""
			}>
			<div className="bg-[#18181B] lg:px-7 lg:py-10 px-5 py-16 rounded m-auto  items-center h-full relative justify-center flex flex-col">
				<div className="mb-10">
					<div className="">
						<p className="">{cost.name}</p>
					</div>
					<p className="text-[#71717A]">
						<span className="lg:text-[60px] text-[40px] text-white font-normal">
							{isDollar ? cost.dollar : cost.naira}
						</span>
						{/* <span>{cost.border ? "" : " / month"}</span> */}
					</p>
					<p className="text-[#71717A]">
						{isDollar ? cost.description2 : cost.description}
					</p>
				</div>
				<div className="w-full h-[1px] bg-[#27272A] mb-16"></div>
				<div className="bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] rounded p-[1px] text-center w-full">
					<div className="bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] rounded p-[1px] text-center w-full">
						{isDollar ? (
							<PaypalCheckout
								amount={cost.dollarAmount}
								description={cost.name}
							/>
						) : (
							<button
								className={
									cost.border
										? "bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] py-2 px-5 rounded w-full"
										: "bg-[#18181B] py-2 px-5 rounded w-full"
								}
								onClick={onOptionClick}>
								Enroll Now
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PriceCard;
