import React from "react";
// import Listing from '../../components/Listing';
// import Button from '../../components/Button';
import left_glow from "../../assetss/left_glow.png";
import right_glow from "../../assetss/right_glow.png";
import bottom_shade from "../../assetss/bottom_shade.png";
import left_shade from "../../assetss/left_shade.png";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <section className="relative h-screen overflow-hidden">
      <div className="h-full grid place-items-center">
        <div className="text-center justify-center items-center flex-col">
          <h2 className="text-[#FDCA01] font-bold text-[131px] md:text-[180px] xl:text-[251px] leading-[150px] md:leading-[200px] xl:leading-[240px]">
            404
          </h2>
          <h3 className="uppercase text-[#FCDA01] text-[21px] md:text-[29px] xl:text-[40px] font-semibold">
            Not Found
          </h3>
          <h3 className="text-white text-[24px] md:text-[35px] xl:text-[48px] font-semibold">
            Whoops! That page doesn't exist.
          </h3>
          <p className="text-white my-[25px]">
            The page you requested could not be found
          </p>
          <Link to={"/"}>
            <button className="bg-[#FDCA01] text-[#2A2145] font-medium rounded-[60px] py-[16px] px-[64px]">
              Back to Home
            </button>
          </Link>
        </div>
      </div>
      <img className="-z-[1] absolute top-0" src={left_glow} alt="" />
      <img
        className="-z-[2] absolute bottom-0 right-0"
        src={bottom_shade}
        alt=""
      />
      <img
        className="-z-[4] absolute left-0 bottom-0"
        src={left_shade}
        alt=""
      />
      <img
        className="-z-[3] absolute right-0 bottom-0"
        src={right_glow}
        alt=""
      />
    </section>
  );
};

export default NotFound;
