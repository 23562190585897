import React from "react";
import { PaystackButton } from "react-paystack";
// import sent from "../assetss/sent.png";

export default function PaymentModal({
  text,
  text2,
  handleClick,
  onChange,
  value,
  amount,
  onClose,
}) {
  return (
    <div>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl ">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full text-white outline-none focus:outline-none bg-[#141021]">
            {/*body*/}
            <div className="relative md:py-16 py-10 md:px-10 px-3 flex-auto text-center items-center align-middle flex flex-col mx-auto">
              {/* <img className="md:w-auto  w-[60px]" src={sent} alt="" /> */}
              <p className="mt-6 text-[#fff] lg:text-xl font-medium md:text-lg text-base md:w-[500px] w-[310px]">
                {text}
              </p>
              <div className="w-full mt-5">
                <label htmlFor="email">
                  <input
                    className="w-full bg-transparent border border-gray-500 py-5 px-5 outline-none text-gray-500 rounded-full"
                    type="email"
                    id="email"
                    name="email"
                    onChange={onChange}
                    value={value}
                    placeholder="Please Input Your Email"
                  />
                </label>
              </div>
              <div className="mt-5">
                <PaystackButton
                  className={
                    true
                      ? "bg-[#FDCA01] text-black hover:cursor-pointer border duration-300 border-yellow-500 font-medium lg:px-[60px] px-[20px] py-[15px] rounded-[50px]"
                      : "bg-[#18181B] py-2 px-5 rounded w-full"
                  }
                  text="Pay Now"
                  publicKey="pk_live_c19f9922e778e1c930e87fd8f9599fb1f69600dc"
                  amount={amount}
                  email={value}
                  onSuccess={handleClick}
                />
              </div>
            </div>
            <div className="flex items-center justify-end p-6 rounded-b">
              <button
                className="absolute top-3 right-0 text-slate-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleClick}
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
