import React from "react";
import TimeIcon from "../assetss/blacktime.png";
import LocIcon from "../assetss/blackloc.png";
import VideoIcon from "../assetss/blackvideo.png";
import PriceIcon from "../assetss/blackprice.png";
import { Link } from "react-router-dom";
import { getUserLocation } from "../services/LocationServices";

const CourseCard = ({ course }) => {
  const userLocation = getUserLocation();
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-7">
      <div className="md:col-span-2 bg-white flex flex-col pb-16">
        <div className="bg-[#FDCA01] text-center w-full py-8 px-6 font-bold text-2xl md:text-[2rem]">
          {course.level} Level
        </div>
        <div className="flex flex-col mt-10 px-8 space-y-5 md:text-lg">
          <p className="flex items-center">
            <span className="mr-3">
              <img src={TimeIcon} alt="time icon" />
            </span>
            {course.duration}
          </p>
          <p className="flex items-center">
            <span className="mr-3">
              <img src={LocIcon} alt="location icon" />
            </span>
            Assisted Learning
          </p>
          <p className="flex items-center">
            <span className="mr-3">
              <img src={VideoIcon} alt="video icon" />
            </span>
            Live Quizzes
          </p>
          <p className="flex items-center font-bold md:font-[900]">
            <span className="mr-3">
              <img src={PriceIcon} alt="price icon" />
            </span>
            {userLocation?.country_name !== "Nigeria"
              ? course.dollarPrice
              : course.nairaPrice}
          </p>
        </div>

        <Link
          to={course.link}
          state={{ data: course }}
          className="flex items-center justify-center mx-8 hover:brightness-150 text-white bg-[#2A2145] duration-300 mt-16 p-4 rounded-[3.75rem]"
        >
          Enrol Now
        </Link>
      </div>

      <div className="md:col-span-3 lg:col-span-5 bg-[#141021] flex flex-col text-white border-[1px] border-[rgba(255,255,255,0.12)] p-8 space-y-4">
        {course.courses.map((item) => (
          <li>
            <span className="font-bold">{item.title}</span> - {item.description}
          </li>
        ))}
      </div>
    </div>
  );
};

export default CourseCard;
