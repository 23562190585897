/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../assetss/footer_logo.png";
// import location from "../assetss/location.png";
import mail from "../assetss/mail.png";
import phone from "../assetss/phone.png";
import { Link } from "react-router-dom";

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    // text-[#4B5768]
    <footer className="text-white">
      <section className="lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px]">
        <div className="">
          <div className="w-[122px]">
            <img src={logo} alt="" className="w-full mb-5" />
          </div>
          <p className="text-white font-medium ">
            Building a community of talented professionals based in Nigeria and
            Sub-Saharan Africa, fit for migration and remote services in the
            emerging cloud and virtualization technology.
          </p>
        </div>
        <section className="text-white grid lg:grid-cols-4 gap-5 border-b-2 py-10">
          <div className="flex lg:mb-0 mb-5">
            <div className="w-[24px] mr-2">
              <img src={phone} className="w-full" alt="" />
            </div>
            <p>
              <span className="block mb-3">Tel</span>
              <span className="mb-3 flex flex-col">
                <a href="tel:+12404217887">+1 (240) 421 7887</a>
                <a href="tel:+23470638227437">(+234) 706 382 2743</a>
              </span>
            </p>
          </div>

          <div className="flex lg:mb-0 mb-5">
            <div className="w-[24px] mr-2">
              <img src={mail} className="w-full" alt="" />
            </div>
            <p>
              <span className="block mb-3">Mail</span>
              <span className="block">
                <a href="mailto:admin@schull.io">admin@schull.io</a>
              </span>
            </p>
          </div>
          <div className="flex">
            <div className="footer-icons text-3xl md:space-x-5 space-x-4 mb-2 md:mb-0">
              <a
                href="https://www.linkedin.com/company/schull-io1/"
                target="__blank"
              >
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>
              <a href="https://twitter.com/Schull__io" target="__blank">
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
              <a
                href="https://instagram.com/schull.io?igshid=YmMyMTA2M2Y="
                target="__blank"
              >
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </div>
          </div>
        </section>
        <div className="md:flex justify-between py-5">
          <div className="flex lg:gap-20 md:gap-10 gap-2 md:mb-0 mb-5">
            <Link to="/About" className="links">
              <p className="">About us</p>
            </Link>
            <Link to="/Contact" className="links">
              <p className="">Contact Us</p>
            </Link>
            <Link to="/Faqs" className="links">
              <p className="">FAQs</p>
            </Link>
            <Link to="/Policy" className="links">
              <p className="">Privacy policy</p>
            </Link>
            <Link to="/Terms&Condition" className="links">
              <p className="">Terms and Condition</p>
            </Link>
          </div>
          <div className="">
            <p className="">© {year} Schull.io, All Rights Reserved</p>
          </div>
        </div>
      </section>
    </footer>
  );
}
