import React from "react";
import PriceCard from "./PriceCard";

function CoursePricingSection({ course, onClick, isDollar }) {
	return (
		<div className="py-16">
			<div className="mb-16">
				<h4 className="lg:text-[40px] md:text-4xl text-3xl text-white font-bold mb-6">
					{course.title}
				</h4>
				<span className="bg-[#F6BE2C] font-medium rounded-large py-2 px-[18px]">
					{course.type}
				</span>
			</div>
			<div className="text-white grid md:grid-cols-3 grid-rows-1  lg:gap-10 md:gap-3 gap-10 mt-10">
				{course.pricingOptions.map((option) => (
					<PriceCard
						cost={option}
						isDollar={isDollar}
						onOptionClick={() => onClick(option.amount * 100)}
					/>
				))}
			</div>
		</div>
	);
}

export default CoursePricingSection;
