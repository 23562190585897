import React from "react";
import timeicon from "../assets/time.svg";
import media from "../assetss/video.png";
import loc from "../assetss/loc.png";
import { useNavigate } from "react-router-dom";

export default function Course(props) {
  const navigate = useNavigate();
  function handleClick() {
    const state = { data: props };
    if (props.id === "devops") {
      navigate("/devops");
    } else {
      navigate("/Single-course", { state });
    }
  }
  return (
    <div
      key={props.id}
      className="lg:w-[353px] bg-[#141021] shadow-lg shadow-slate-800 cursor-pointer"
      onClick={handleClick}
    >
      <div className="lg:w-[353px]">
        <img src={props.thumbnail} className="w-full" alt="course thumbnail" />
      </div>
      <div className="px-4 py-10">
        <p className="text-[#FFFFFF] lg:text-2xl text-xl font-medium">
          {props.title}
        </p>
        <p className="text-[#FFFFFFB2] my-3">{props.description}</p>
        <div className="text-[#A1A1AA] font-normal">
          <p className="flex items-center">
            <span className="mr-3">
              <img className="" src={timeicon} alt="time icon" />
            </span>
            <span className="">{props.time}</span>
          </p>
          <p className="flex items-center my-3">
            <span className="mr-3">
              <img className="" src={loc} alt="time icon" />
            </span>
            <span className="">Assisted Learning</span>
          </p>
          <p className="flex items-center">
            <span className="mr-3">
              <img className="" src={media} alt="time icon" />
            </span>
            <span className="">Live Quizzes</span>
          </p>
        </div>
      </div>
    </div>
  );
}
