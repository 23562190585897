export const devopsPricing = [
  {
    id: 0,
    name: "Five Installments",
    naira: "₦194K",
    description:
      "Make a 40% down payment of 194,000 NGN and complete the rest in a spread payment of 70,000 NGN monthly for the first 5 months of the program",
    dollar: "$500",
    dollarAmount: 500,
    description2:
      "Pay your tuition fee 42% upfront. Complete your payment within the first three  months.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: false,
    amount: 194000,
    handlePayment: () => {
      // handlClick(250000 * 100);
    },
  },
  {
    id: 1,
    name: "Full Tuition Payment",
    naira: "₦485K",
    description:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    dollar: "$1200",
    dollarAmount: 1200,
    description2:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: true,
    amount: 485000,
    handlePayment: () => {
      // handlClick(450000 * 100);
    },
  },
  {
    id: 2,
    name: "Two Installments",
    naira: "₦250K",
    description:
      "Make a 50% down payment of 250,000 NGN and complete the rest before the conclusion of the third month of the program",
    dollar: "$625",
    dollarAmount: 625,
    description2:
      "Pay your tuition fee 50% upfront. Complete your payment within the first five months.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: false,
    amount: 250000,
    handlePayment: () => {
      // handlClick(250000 * 100);
    },
  },
];

export const solutionsPricing = [
  {
    id: 0,
    name: "Two Installments",
    naira: "₦90K",
    description:
      "Two Installment Plan - You’ll be required to pay 50% of the tuition of N90,000 and complete the rest within the 3rd week of your stay in the program.",
    dollar: "$204",
    dollarAmount: 204,
    description2:
      "Two Installment Plan - You’ll be required to pay 50% of the tuition of $408 and complete the rest within the 3rd week of your stay in the program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: false,
    amount: 90000,
    handlePayment: () => {
      // handlClick(90000 * 100);
    },
  },
  {
    id: 1,
    name: "Full Tuition Payment",
    naira: "₦170K",
    description:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    dollar: "$408",
    dollarAmount: 408,
    description2:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: true,
    amount: 170000,
    handlePayment: () => {
      // handlClick(170000 * 100);
    },
  },
];

export const cloudPricing = [
  {
    id: 0,
    name: "Two Installments",
    naira: "₦66K",
    description:
      "You’ll be required to pay 50% of the tuition of N66,000 and complete the rest within the 3rd week of your stay in the program.",
    dollar: "$144",
    dollarAmount: 144,
    description2:
      "You’ll be required to pay 50% of the tuition of $288 and complete the rest within the 3rd week of your stay in the program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: false,
    amount: 66000,
    handlePayment: () => {
      // handlClick(66000 * 100);
    },
  },
  {
    id: 1,
    name: "Full Tuition Payment",
    naira: "₦120K",
    description:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    dollar: "$288",
    dollarAmount: 288,
    description2:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: true,
    amount: 120000,
    handlePayment: () => {
      // handlClick(120000 * 100);
    },
  },
];

export const combinedPrices = [
  {
    id: 0,
    name: "Two Installments",
    naira: "₦150K",
    description:
      "Two Installment Plan - You’ll be required to pay 50% of the tuition of N150,000 and complete the rest within the 3rd week of your stay in the program.",
    dollar: "$384",
    dollarAmount: 384,
    description2:
      "Two Installment Plan - You’ll be required to pay 50% of the tuition of $696 and complete the rest within the 3rd week of your stay in the program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: false,
    amount: 150000,
    handlePayment: () => {
      // handlClick(150000 * 100);
    },
  },
  {
    id: 1,
    name: "Full Tuition Payment",
    naira: "₦290K",
    description:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    dollar: "$696",
    dollarAmount: 696,
    description2:
      "Pay your tuition fee 100% upfront. No other charges come up during your program.",
    url: "https://paystack.com/pay/schull-sub3-balance",
    border: true,
    amount: 290000,
    handlePayment: () => {
      // handlClick(290000 * 100);
    },
  },
];

export const courses = [
  {
    id: 0,
    title: "DevOps Course",
    type: "Full Course",
    pricingOptions: devopsPricing,
  },
  {
    id: 1,
    title: "DevOps Foundational",
    type: "Foundational Course",
    pricingOptions: devopsPricing,
  },
  {
    id: 2,
    title: "DevOps Advanced",
    type: "Advanced Course",
    pricingOptions: devopsPricing,
  },
  {
    id: 3,
    title: "AWS Solutions Architect Associate (SAA-C03)",
    type: "Short Course",
    pricingOptions: solutionsPricing,
  },
  {
    id: 4,
    title: "AWS Certified Cloud Practitioner (CLF-C02)",
    type: "Short Course",
    pricingOptions: cloudPricing,
  },
  {
    id: 5,
    title: "Combined AWS CP & AWS SA",
    type: "Short Course",
    pricingOptions: combinedPrices,
  },
];
