import React from "react";
import jason_dp from "../../assetss/jason_dp.png";
import tife from "../../assetss/tife.png";
import adebo from "../../assetss/adebo.jpg";
import adedolapo from "../../assetss/adedolapo.jpg";
import slider_glow from "../../assetss/slider_glow.png";
import damilola from "../../assetss/damilola.jpg";
import feyisayo from "../../assetss/feyisayo.jpg";
import daniel from "../../assetss/daniel.jpg";
import daniels from "../../assetss/daniels.jpg";
import segun from "../../assetss/segun.jpg";
// import raphael from '../../assetss/raphael.jpg'

export default function Slider() {
	const stories = [
		{
			comment:
				"Joining schull.io training was one of the best decisions I've ever made towards my career. I transitioned into tech towards the end of 2021 and schull.io helped me achieve that. I've made significant progress in my tech career today, going from being a complete novice to being market ready as a Cloud Engineer. Schull.io's training is unquestionably worthwhile.",
			name: "Boluwatife Olufunso",
			role: "Certified Cloud Practitioner",
			img: tife,
		},
		{
			comment:
				"The cloud engineering bootcamp organized by Schull.io was an awesome experience for me. I just heard about cloud engineering at the time, I literally had no background in IT, then this opportunity came and I ran with it, now I look back and confidently say I made a pretty good decision. The training not only gave me the required knowledge but also exposed me to the soft skills I needed to navigate through my career journey and Tech industry at large. Through my training with Schull.io, I was able to land a job as a Cloud Engineer.",
			name: "Adedolapo Akintunde",
			role: "Certified Cloud Practitioner",
			img: adedolapo,
		},
		{
			comment:
				"WIth a degree in computer science, I dabbled in networking but I always wanted to move into the cloud space. When Schull.io came with the bootcamp, it was the perfect opportunity to take steps towards acheiving this. I am glad I made time and stuck through it. Alongside my cloud training, I was exposed so Soft Skills and also got Agile Training. A few months down the line I got a job as a Cloud Engineer.",
			name: "Adebo Odunsi",
			role: "Cloud Engineer",
			img: adebo,
		},
		{
			comment:
				"I opted in for schull.io program to develop my skills in order  to have a career in cloud engineering and to be well prepared for the Solutions Architect certification. I can say that I am  well equipped to take on projects relating to Cloud Engineering. The program was a bit rigorous and challenging at first because we had to learn a lot of new concepts and go in depth into different cloud offerings  but with the help of the hands-on and tutors, it became quite easy over time.The soft skills and agile training were the icing on cake.  I would like to encourage everyone to seize the opportunity to become a cloud guru.",
			name: "Damilola Tytler",
			role: "Certified Cloud Practitioner",
			img: damilola,
		},
		{
			comment:
				"Schull.io has really been a wonderful Edu Tech platform as regards HTML & CSS, AWS, and the various cloud computing tools needed to become a well furnished Cloud Engineer.The tutors were always there to help and assist us most of the time when we are stuck, confused, and needed help. They were always there to put us through and made sure we understood what we needed to know.  I can categorically say my expectations were met, I now understand and work with most of the concepts I learnt in this chosen field. I want to appreciate the schull.io team for putting this all together to empower and pass knowledge down to young folks.",
			name: "Daniel Chris",
			role: "Schuller",
			img: daniels,
		},
		{
			comment:
				"Prior to now, I was a travel consultant, I did not have any inclination on Cloud, programming or whatnot. Schull.io is one of the places to reckon with in terms of Information Technology generally, digital and virtual learning which you can tune in anywhere in the world for a live class. Although, I am not done with the program but I have learnt a lot when it comes to Cloud platforms especially AWS. I can confidently build up a cloud related project using the concept that I now know. I have really enjoyed myself so far in the program, hoping to enjoy more of Schull.io  My expectations were met exceedingly because prior to this time I haven’t done a long time virtual learning or have anything to do in the cloud. All the tutors were very helpful and fantastic. Thanks to the team for coming up with this platform.",
			name: "Feyisayo Ogunnupebi",
			role: "Schuller",
			img: feyisayo,
		},
		{
			comment:
				"At first everything seemed very confusing because I had no knowledge of the Linux OS. As time went on, I got a grasp  of it.  The LMS, quizzes, weekly hands-on, live classes, the triad system,  the tutors and other participants were all I needed to succeed in the program and that is what I would say helped me thrive. The hands-on were quite challenging but with the help of the tutors, I was able to pull through, and my expectations were met.",
			name: "Daniel Omoleye",
			role: "Schuller",
			img: daniel,
		},
		{
			comment:
				"Schull.io is the platform I learnt about Cloud computing and its various concepts and building infrastructures .Thanks to the tutors and the team, my expectations were met. It was an awesome and challenging experience having to do the hands on, join the weekly classes and combine it with other activities from my end but above all it was a very interesting experience Schull.io is a place you can kickstart your Tech journey and you won’t regret it.",
			name: "Segun Micheal",
			role: "Certified Cloud Practitioner",
			img: segun,
		},
	];

	const [activeSlide, setActiveSlide] = React.useState(0);

	const prevSliderHandler = (index) => {
		if (index === 0) {
			setActiveSlide(stories.length - 1);
		} else if (index > 1) {
			setActiveSlide(activeSlide - 1);
		} else {
			setActiveSlide(stories.length - stories.length);
		}
	};

	const nextSliderHandler = (index) => {
		if (index === stories.length - 1) {
			setActiveSlide(0);
		} else if (index < stories.length - 1) {
			setActiveSlide(activeSlide + 1);
		} else {
			setActiveSlide(stories.length - 1);
		}
	};

	return (
		<>
			<div className="pt-12">
				{stories.map((item, index) => {
					return (
						<section
							key={index}
							className={activeSlide === index ? "block" : "hidden"}>
							<section className="lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px] grid md:grid-cols-2 grid-rows-1 text-white my-5">
								<div className="pr-[80px]">
									<h3 className="font-medium lg:text-[54px] md:text-[45px] text-[24px] md:leading-[60px] leading-[30px] mb-5">
										Our Schullers' Success Stories
									</h3>
									<p className="font-normal text-md text-[#A1A1AA] leading-7 mb-5">
										What our Talents say about Schull.io
									</p>
									<div className="lg:mb-0 md:mb-0 mb-10">
										<button
											onClick={() => prevSliderHandler(index)}
											className="hover:bg-[#FDCA01] bg-[#141021] hover:text-[#333] duration-200 rounded-full border border-[#FDCA01] w-[56px] h-[56px] mr-10">
											&#8592;
										</button>
										<button
											onClick={() => nextSliderHandler(index)}
											className="hover:bg-[#FDCA01] bg-[#141021] hover:text-[#333] rounded-full border border-[#FDCA01] w-[56px] h-[56px]">
											&#8594;
										</button>
									</div>
								</div>
								<section className="text-[#A1A1AA]">
									<div className="relative border-2 px-5 py-8 rounded-2xl border-gray-700 overflow-hidden">
										<img
											className="absolute bottom-0 left-0 right-0 w-full h-full"
											src={slider_glow}
											alt=""
										/>
										<p className="mb-5 font-normal text-base">{item.comment}</p>
										<div className="flex">
											<div className="w-[56px] h-[56px] rounded-full mr-3">
												<img
													src={item.img}
													className="w-full rounded-full"
													alt=""
												/>
											</div>
											<div className="">
												<p className="Name ">{item.name}</p>
												<p className="Role ">{item.role}</p>
											</div>
										</div>
									</div>
								</section>
							</section>
						</section>
					);
				})}
			</div>
		</>
	);
}
