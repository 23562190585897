import React, { useEffect } from "react";
import About from "./Pages/About/About";
import HeaderFooterLayout from "./layout/HeaderFooterLayout";
import Terms from "./Pages/Terms/Terms";
import Page from "./Pages/Landing Page/Page";
import Contact from "./Pages/Contact/Contact";
import Policy from "./Pages/Policy/Policy";
import Cost from "./Pages/Cost/Cost";
import Faqs from "./Pages/Faq/Faqs";
import Curriculum from "./Pages/Curriculum/Curriculum";
import Form from "./Pages/Form/Form";
import Courses from "./Pages/Courses/Courses";
import SingleCourse from "./Pages/Courses/SingleCourse";
import NotFound from "./Pages/NotFound";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import DevOps from "./Pages/Courses/DevOps";
import Level from "./Pages/Courses/Level";
import {
  fetchUserLocation,
  getUserLocation,
} from "./services/LocationServices";

// import Promo from './Pages/Promo/Promo';
// import Promos_payment from './Pages/Promo/Promos_payment';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="" element={<HeaderFooterLayout />}>
        <Route index element={<Page />} />
        <Route path="/Signup" element={<Form />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/Single-course" element={<SingleCourse />} />
        <Route path="/devops" element={<DevOps />} />
        <Route path="/course/:level" element={<Level />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/Terms&Condition" element={<Terms />} />
        <Route path="/Pricing" element={<Cost />} />
        <Route path="/Faqs" element={<Faqs />} />
        <Route path="/Curriculum" element={<Curriculum />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </>
  )
);
function App() {
  useEffect(() => {
    if (getUserLocation() === null) {
      fetchUserLocation();
    }
  }, []);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
