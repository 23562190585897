import React from "react";
import PaypalCheckout from "../../components/PaypalCheckout";
import CoursePricingSection from "../../components/CoursePricingSection";
import { courses } from "../../data";

export default function MainUS() {
	// const cost = [
	// 	{
	// 		id: 1,
	// 		name: "Full Tuition Payment",
	// 		description:
	// 			"Pay your tuition fee 100% upfront. No other charges come up during your program.",
	// 		dollar: 1200,
	// 		border: true,
	// 	},
	// 	{
	// 		id: 2,
	// 		name: "Two installments",
	// 		description:
	// 			"Pay your tuition fee 50% upfront. Complete your payment within the first five months.",
	// 		dollar: 625,
	// 		border: true,
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "Five Installments",
	// 		description:
	// 			"Pay your tuition fee 42% upfront. Complete your payment within the first three  months.",
	// 		dollar: 500,
	// 		border: true,
	// 	},
	// ];

	// const price = cost.map((cost) => {
	// 	return (
	// 		<div
	// 			key={cost.id}
	// 			className={
	// 				cost.border
	// 					? "bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] rounded p-[1px]"
	// 					: ""
	// 			}>
	// 			<div className="bg-[#18181B] lg:p-7 p-3 rounded m-auto  items-center h-full relative">
	// 				<div className="pb-5">
	// 					<div className="flex justify-between items-center">
	// 						<p className="">{cost.name}</p>
	// 					</div>
	// 					<p className="text-[#71717A]">
	// 						<span className="lg:text-[60px] text-[40px] text-white font-normal">
	// 							${cost.dollar}
	// 						</span>
	// 						{cost.month}
	// 					</p>
	// 					<p className="text-[#71717A]">{cost.description}</p>
	// 				</div>
	// 				<PaypalCheckout amount={cost.dollar} description={cost.name} />
	// 			</div>
	// 		</div>
	// 	);
	// });

	return (
		<>
			<section className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[140px] px-[20px] py-[160px]">
				<p className="lg:text-[56px] md:text-[46px] text-[36px] text-white font-bold">
					Pricing
				</p>

				{/* <div className="text-white grid md:grid-cols-3 grid-rows-1 lg:gap-10 gap-3 mt-10">
          {price}
        </div> */}

				<div className="w-full border border-white/20 mt-12" />
				<CoursePricingSection
					course={courses[0]}
					isDollar={true}
					// onClick={handlClick}
				/>
				<div className="w-full border border-white/20 mt-12" />
				<CoursePricingSection
					course={courses[1]}
					isDollar={true}
					// onClick={handlClick}
				/>
				<div className="w-full border border-white/20 mt-12" />
				<CoursePricingSection
					course={courses[2]}
					isDollar={true}
					// onClick={handlClick}
				/>
				<div className="w-full border border-white/20 mt-12" />
				<CoursePricingSection
					course={courses[3]}
					isDollar={true}
					// onClick={handlClick}
				/>
			</section>
		</>
	);
}
