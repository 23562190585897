import React from "react";
import AboutMain from "./AboutMain";
import ScrollToTop from "../../components/ScrollToTop";

export default function About() {
  return (
    <>
      <ScrollToTop />
      <AboutMain />
    </>
  );
}
