import React, { useState } from "react";
import PaymentModal from "../../components/PaymentModal";
import CoursePricingSection from "../../components/CoursePricingSection";
import { courses } from "../../data";

export default function Main() {
  const [showModal, setShowModal] = React.useState(false);
  const [amount, setAmount] = useState("");
  const [formData, setFormData] = useState({ email: "" });

  function handlClick(amount) {
    setAmount(amount);
    setShowModal(true);
  }
  function handleChange(e) {
    e.preventDefault();
    const { value, name } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <>
      <section className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[140px] px-[20px] py-[160px]">
        <p className="lg:text-[56px] md:text-[46px] text-[36px] text-white font-bold">
          Pricing
        </p>
        <p className="text-[#FDCA01] lg:text-[16px] font-normal">
          Kindly note that the Registeration fee of N15,000 is not inclusive of
          the tuition fee
        </p>

        <div className="w-full border border-white/20 mt-12" />

        <CoursePricingSection course={courses[0]} onClick={handlClick} />
        <div className="w-full border border-white/20 mt-12" />
        <CoursePricingSection course={courses[1]} onClick={handlClick} />
        <div className="w-full border border-white/20 mt-12" />
        <CoursePricingSection course={courses[2]} onClick={handlClick} />
        <div className="w-full border border-white/20 mt-12" />
        <CoursePricingSection course={courses[3]} onClick={handlClick} />
      </section>
      <section>
        {/* <Modal /> */}
        {showModal ? (
          <>
            <PaymentModal
              amount={amount}
              onChange={handleChange}
              value={formData.email}
              text="Please Input Your Email"
              handleClick={() => {
                setShowModal(false);
                setFormData({ email: "" });
              }}
            />
          </>
        ) : null}
      </section>
    </>
  );
}
