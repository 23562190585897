import axios from "axios";

const mailingAPI =
  "https://r150n7ncna.execute-api.us-east-1.amazonaws.com/prod/mailing";

const sendEmail = (email, mailingBody) => {
  const mailingData = {
    config: {
      fromEmailAddress: "schull.io@schull.io",
      toEmailAddress: email,
    },
    body: mailingBody,
  };

  return axios.post(mailingAPI, mailingData);
};

export const sendEmailsIteratively = async (emails, mailBody) => {
  const errors = [];

  for (let i = 0; i < emails.length; i++) {
    try {
      await sendEmail(emails[i], mailBody);
      console.log(`Email sent to: ${emails[i]}`);
    } catch (error) {
      console.error(`Failed to send email to: ${emails[i]}`, error);
      errors.push({ email: emails[i], error });
    }
  }

  if (errors.length > 0) {
    throw errors;
  }
};
