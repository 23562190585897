import React from "react";

export default function TermsComponent({title, list}) {
  return (
    <article className="text-white mb-8">
      <p className="text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium">
        {title}
      </p>
      <ul className="lg:text-[21px] md:text-[18px] text-[16px] font-normal list-disc translate-x-[40px]">
        {list.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    </article>
  );
} 
