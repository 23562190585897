import React from "react";
import ReactPlayer from "react-player";

const Video = () => {
	return (
		<section>
			<div className="lg:px-[81px] px-[20px] py-40">
				<div className="flex items-center justify-center h-[450px] lg:h-[500px]">
					<ReactPlayer
						url="https://youtu.be/kHJEqztD_RI"
						width="100%"
						height="100%"
						controls={true}
					/>
				</div>
			</div>
		</section>
	);
};

export default Video;
