import React from "react";
import Button from "../../components/Button";
import tochi from "../../assetss/tochi.png";
import adura from "../../assetss/adura.png";
import jide from "../../assetss/jide.png";
import diagonal_rec from "../../assetss/diagonal_rec.png";
import vertical_rec from "../../assetss/vertical_rec.png";
import glow from "../../assetss/glow.png";
import { Link } from "react-router-dom";

export default function Career() {
	return (
		<>
			<section className="lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px] md:hidden">
				<section className="md:grid md:grid-cols-2">
					<div className="relative h-[400px] md:h-auto">
						<div className="w-full h-full">
							<img className="w-full h-full" src={glow} alt="" />
						</div>

						<div className="w-[150px] absolute top-10 left-7">
							<img className="w-full" src={vertical_rec} alt="" />
						</div>
						<div className="w-[200px] absolute top-14 left-14">
							<img className="w-full" src={diagonal_rec} alt="" />
						</div>
						<div className="w-[120px] absolute top-8 left-4">
							<img className="w-full" src={tochi} alt="" />
						</div>
						<div className="w-[120px] absolute bottom-10 left-4">
							<img className="w-full" src={adura} alt="" />
						</div>
						<div className="w-[120px] absolute top-36 right-16">
							<img className="w-full" src={jide} alt="" />
						</div>
						{/* <p className='flex flex-col absolute top-0 left-7'>
                        <span className='text-white text-[9.5px] font-normal'>Tochukwu Nwachukwu</span>
                        <span className='text-[#A1A1AA] text-[7.3px] font-normal'>Certified AWS Developer</span>
                    </p> */}
						{/* <p className='flex flex-col absolute bottom-0 left-7'>
                        <span className='text-white text-[9.5px] font-normal'>Aduragbemi Babatunde</span>
                        <span className='text-[#A1A1AA] text-[7.3px] font-normal'>Cloud Practitioner</span>
                    </p> */}
						{/* <p className='flex flex-col absolute top-72 right-10'>
                            <span className='text-white text-[9.5px] font-normal'>Jide Sanyaolu</span>
                            <span className='text-[#A1A1AA] text-[7.3px] font-normal'>Certified Linux System Administrator</span>
                        </p> */}
					</div>
					<div className=" mt-8">
						<div className="text-left">
							<h2 className="lg:leading-[62px] md:leading-[45px] lg:text-[50px] md:text-[38px] text-[24px] leading-[32px] font-medium lg:mb-10 mb-5 text-white">
								The Knowledge You Need to Begin{" "}
								<span className="text-[#FDCA01]">a Career in DevOps</span>
							</h2>
							<p className="lg:leading-[26px] md:leading-[18px] text-[#A1A1AA] text-md font-normal lg:mb-10 md:mb-8 mb-8">
								Advancing into DevOps requires more than just acquiring
								certifications. It demands real-world experience before being
								hired by employers, but how will you gain the experience without
								employment ? Our curated training program will expose you to
								real projects so you acquire skills and be job ready in 4-5
								months.
							</p>
							<Link to="/Curriculum" className="links">
								<Button
									text="See Curriculum"
									custom="bg-[#FDCA01] text-black"
								/>
							</Link>
						</div>
					</div>
				</section>
			</section>

			<section className="lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px]  md:grid-cols-2 grid-rows-1 text-white my-5 hidden md:grid">
				<div className="relative">
					<div className="absolute lg:left-[-200px] md:left-[-160px] top-[-50px]">
						<img className="w-full" src={glow} alt="" />
					</div>
					<div className="absolute top-1 left-6">
						<img className="lg:w-full md:w-[70%]" src={diagonal_rec} alt="" />
					</div>
					<div className="absolute lg:top-[90px] md:top-[50px] left-8">
						<img className="lg:w-full md:w-[80%]" src={vertical_rec} alt="" />
					</div>
					<div className="absolute lg:top-[-6px] lg:left-0 md:left-5 lg:w-[199px] md:w-[120px]">
						<img className="w-full" src={tochi} alt="" />
					</div>
					<div className="absolute lg:top-[320px] md:top-[220px] lg:left-0 md:left-5 lg:w-[199px] md:w-[120px]">
						<img className="w-full" src={adura} alt="" />
					</div>
					<div className="absolute lg:top-[200px] md:top-[150px] lg:left-[250px] md:left-[180px] lg:w-[199px] md:w-[110px]">
						<img className="w-full" src={jide} alt="" />
					</div>
					{/* <div className='absolute top-[-80px] lg:left-[-6px] md:left-[26px]'>
                    <h3 className='lg:text-lg font-normal leading-7'>Tochukwu Nwachukwu</h3>
                    <p className='text-sm font-normal leading-5 text-[#A1A1AA]'>Certified AWS Developer</p>
                </div> */}
					{/* <div className='absolute lg:bottom-[-140px] lg:left-[10px] md:bottom-[-60px] md:right-[150px]'>
                    <h3 className='lg:text-lg font-normal leading-7'>Aduragbemi Babatunde</h3>
                    <p className='text-sm font-normal leading-5 text-[#A1A1AA]'>Cloud Practitioner</p>
                </div> */}
					{/* <div className='absolute lg:bottom-[-50px] lg:left-[270px] md:bottom-0 md:left-[190px]'>
                    <h3 className='lg:text-lg font-normal leading-7'>Jide Sanyaolu</h3>
                    <p className='text-sm font-normal text-[#A1A1AA]'>Certified Linux System Administrator</p>
                </div> */}
				</div>
				<div className="text-left">
					<h2 className="lg:leading-[62px] md:leading-[45px] lg:text-[50px] md:text-[38px] font-medium lg:mb-10 md:mb-5">
						The Knowledge You Need to Begin{" "}
						<span className="text-[#FDCA01]">a Career in DevOps</span>
					</h2>
					<p className="lg:leading-[26px] md:leading-[18px] text-[#A1A1AA] text-md font-normal lg:mb-10 md:mb-8">
						Advancing into DevOps requires more than just acquiring
						certifications. It demands real-world experience before being hired
						by employers, but how will you gain the experience without
						employment ? Our curated training program will expose you to real
						projects so you acquire skills and be job ready in 4-5 months.
					</p>
					<Link to="/Curriculum" className="links">
						{/* <button className="hover:cursor-pointer border hover:bg-yellow-500 bg-[#141021] hover:text-black duration-300 border-yellow-500 text-[#FFFFFF] text-xs lg:px-[40px] px-[20px] py-[15px] rounded-[50px]">
							See Curriculum
						</button> */}
						<Button text="See Curriculum" custom="bg-[#FDCA01] text-black" />
					</Link>
				</div>
			</section>
		</>
	);
}
