// This is a reusable function that validates that a date is not in the past.
// It accepts a date in the format YYYY - MM - DD and return in a clear readable format
// It requires dayjs npm dependency to work.


import dayjs from "dayjs";

export const validateDateIsInPast = (date) => {
    if (dayjs().isAfter(dayjs(date))) {
        return "To Be Decided"
    } else {
        return dayjs(date).format("MMM D, YYYY")
    }
}