/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import perks_glow from "../../assetss/perks_glow.png";
import whatsapp from "../../assets/whatsapp.svg";
import Modal from "../../components/Modal";
import axios from "axios";

export default function Main() {
  const [showModal, setShowModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });

  function handleChange(e) {
    e.preventDefault();
    const { value, name } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleClick(e) {
    setErrorMessage(null);
    e.preventDefault();
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.phone === "" ||
      formData.message === "" ||
      formData.email === ""
      // setErrorMessage("Message sent successfully")
    ) {
      setErrorMessage("Please fill all fields");
      return;
    }
    axios
      .post(mailingAPI, mailingData)
      .then((response) => {
        // console.log(response);
        setShowModal(true);
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            "Sorry... something went wrong on our side, please try again"
          );
        }
      });
    // console.log(formData)
  }

  const mailingAPI =
    "https://r150n7ncna.execute-api.us-east-1.amazonaws.com/prod/mailing";

  const mailingData = {
    config: {
      fromEmailAddress: "schull.io@schull.io",
      toEmailAddress: "oabiodun@schulltech.com",
    },
    body: {
      subject: "Contact Form",
      message: `<strong>Name: ${formData.firstname} ${formData.lastname}</strong><br>
          <strong>Email:</strong> ${formData.email}<br>
          <strong>Phone:</strong> ${formData.phone}<br>
          <strong>message:</strong> ${formData.message}`,
    },
  };

  return (
    <section className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[140px] px-[20px] py-[200px] relative overflow-hidden">
      <img
        src={perks_glow}
        className="absolute z-[-1] lg:top-[20px] md:top-[20px] top-[20px] lg:left-[30px] md:left-[30px] left-[20px]"
        alt=""
      />
      <div className="lg:w-[1000px] mx-auto text-white bg-[#141021] md:p-8 p-1">
        <p className="text-[#FDCA01] md:text-[32px] text-[24px] font-medium mb-3">
          Need help? Get in touch
        </p>
        <p className="text-[14px] font-normal mb-16">
          Contact us for more enquiries.
        </p>
        <section className="">
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <label>
              First Name: <br />
              <input
                className="w-full bg-black lg:py-5 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
                type="text"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            </label>
            <label>
              Last Name: <br />
              <input
                className="w-full bg-black lg:py-5 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
                type="text"
                name="lastname"
                onChange={handleChange}
                value={formData.lastname}
              />
            </label>
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <label>
              Email Address: <br />
              <input
                className="w-full bg-black lg:py-5 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
            </label>
            <label>
              Phone Number: <br />
              <input
                className="w-full bg-black lg:py-5 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
                type="number"
                name="phone"
                onChange={handleChange}
                value={formData.phone}
              />
            </label>
          </div>
          <div className="">
            <label>
              Message: <br />
              <textarea
                className="w-full bg-black lg:py-5 lg:px-5 h-[200px] rounded-lg mt-2 outline-none"
                type="text"
                name="message"
                onChange={handleChange}
                value={formData.message}
              />
            </label>
          </div>
          <p className="self-end font-light text-right text-sm mt-[1px] ">
            {errorMessage}
          </p>
        </section>
        <div className="">
          <input
            className="hover:cursor-pointer border bg-yellow-500 text-black border-yellow-500  md:px-[80px] px-[50px] py-[19px] rounded-[50px] my-10"
            onClick={handleClick}
            type="submit"
            value="Send Message"
          />
          <div className="lg:w-[30%] md:w-[40%] w-[65%] text-center flex flex-col items-center">
            <p className="font-normal md:text-base text-sm">
              <span className="block mb-5">OR</span>
              <span className="">Chat with our representative </span>
            </p>
            <a
              href="https://wa.me/2348038810723"
              className="mt-5"
              target="__blank"
            >
              <button className="flex items-center justify-center bg-white text-black md:w-[269px] w-[200px] h-[58px] rounded-[50px]">
                <img src={whatsapp} alt="whatsapp" className="" />
                <p>WhatsApp</p>
              </button>
            </a>
          </div>
        </div>
      </div>
      <section>
        {/* <Modal /> */}
        {showModal ? (
          <>
            <Modal
              text=" We appreciate you reaching out to us; your message has
            been received. Our representative will be in touch with
            you soon."
              handlClick={() => {
                setShowModal(false);
              }}
            />
          </>
        ) : null}
      </section>
    </section>
  );
}
