import React from "react";
import ContactUs from "../../components/ContactUs";
import img1 from "../../assetss/aboutnew.png";
import { Link } from "react-router-dom";

export default function AboutMain() {
  return (
    <section className="pt-[90px]">
      <section className="px-5 md:py-10 md:px-10 lg:py-11 lg:px-20">
        <div className="grid gap-10 grid-row-1 lg:grid-cols-2 text-white my-10 justify-items-center">
          <div className="w-[280px] lg:w-auto">
            <img src={img1} alt="" className="w-full" />
          </div>
          <div className="">
            <div className="mb-8">
              <h1 className="font-bold text-3xl mb-5 text-[#fdca01]">
                ABOUT US
              </h1>
              <p className="">
                Schull.io’s non-traditional teaching approach efficiently
                provides training to specific individuals who are passionate
                about technology. At Schull.io, we take pride in our ability to
                train top-notch Cloud DevOps Engineers in an ever-expanding
                technological industry.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-bold text-3xl mb-5 text-[#fdca01]">
                OUR STORY
              </h2>
              <p>
                Schull.io is an edu-tech platform that is building a space where
                anyone from Africa can have the opportunity to be equipped with
                the best Tech practices that will give them a voice anywhere in
                the world.
              </p>
              <p>
                It is our goal to level the playing field to the global standard
                by building and enabling the African Cloud and DevOps
                Engineering Community.
              </p>
              <p>
                We have successfully trained a few hundred world-class
                technology infrastructure engineers in Cloud Engineering,
                DevOps, and Cybersecurity fields and prepared several others for
                certification as Cloud Practitioners or Solutions Architects.
                Our programs are taught using a well-structured and
                internationally recognized curriculum.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-bold text-3xl mb-5 text-[#fdca01]">
                OUR GOAL
              </h2>
              <p className="mb-2">
                In five years, we aim to train and deploy 10,000 world class
                technology infrastructure engineers in cloud engineering,
                DevOps, and cybersecurity fields. Let's help you kickstart your
                career and plug in on global opportunities. Regardless of your
                background in tech, our talent pipeline will give you the tools
                to thrive in the digital world.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-bold text-3xl mb-5 text-[#fdca01]">
                OUR MISSION
              </h2>
              <p className="mb-2">
                To build a community of talented professionals based in Nigeria
                and Sub-Saharan Africa, fit for migration and remote services in
                the emerging cloud and virtualization technology.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="px-5 md:py-10 md:px-10 lg:py-11 lg:px-20">
        <Link to="/Contact" className="links">
          <ContactUs />
        </Link>
      </section>
    </section>
  );
}
