import React from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import perks_glow from "../../assetss/perks_glow.png";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

export default function Number() {
	const [counterOn, setCounterOn] = React.useState(false);

	return (
		<>
			<section className="lg:px-[81px] lg:pt-[54px] lg:pb-[100px] md:px-[30px] md:pt-[40px] md:pb-[100px] px-[20px] pt-[20px] pb-[60px] md:mt-0 mt-10 relative overflow-hidden">
				<section className="grid lg:grid-cols-2 gap-10 grid-rows-1">
					<div className="my-auto text-left lg:py-10 lg:pr-10">
						<p className="text-white md:text-6xl text-2xl font-normal md:leading-[60px] lg:w-[500px] md:w-[420px] w-[207px] md:mb-8 mb-5">
							Numbers are telling our story
						</p>
						<p className="text-[#A1A1AA] font-normal text-lg lg:w-[440px] md:w-[400px] mb-10">
							{" "}
							Our Approach to learning is one of a kind without equal. Check out
							our success metrics.
						</p>
						<Link to="/Signup" className="links">
                        <Button text='Register'/>
                    </Link>
						{/* <a
							className="hover:cursor-pointer border border-yellow-500 px-[40px] py-[8px] rounded-[50px] hover:bg-yellow-500 text-white hover:text-black duration-300"
							href="https://forms.zohopublic.com/schulltechnologies/form/SignupCOLLECTION20/formperma/Lg16zxjR6X--VAk_g7-OTsTqn-e0CASJ-FAo0WHp_D0"
							target="__blank">
							Register Now
						</a> */}
					</div>
					<div className="lg:p-10  lg:m-auto">
						<ScrollTrigger
							onEnter={() => setCounterOn(true)}
							onExit={() => setCounterOn(false)}>
							<div className="bg-[#000000b9] lg:text-left text-center lg:w-[426px]">
								<div className="lg:p-7 p-5">
									<h3 className="text-white text-6xl font-normal leading-[60h3px] pb-3">
										{counterOn && (
											<CountUp start={0} end={95} duration={1} delay={0} />
										)}
										%
									</h3>
									<p className="text-[#A1A1AA] font-normal text-base">
										Talents had ZERO background in I.T before joining.
									</p>
								</div>
								<div className="bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] py-[2px]">
									<div className="lg:p-7 py-5 bg-[#000000]">
										<p className="text-white text-6xl font-normal leading-[60px] pb-3">
											{counterOn && (
												<CountUp start={0} end={2500} duration={3} delay={0} />
											)}
											+
										</p>
										<p className="text-[#A1A1AA] font-normal text-base">
											Number of Schullers and Mentors
										</p>
									</div>
								</div>
								<div className="lg:p-7 p-5">
									<p className="text-white text-6xl font-normal leading-[60px] pb-3">
										{counterOn && (
											<CountUp start={0} end={85} duration={2} delay={0} />
										)}
										%
									</p>
									<p className="text-[#A1A1AA] font-normal text-base">
										Knowledge Retention
									</p>
								</div>
							</div>
						</ScrollTrigger>
					</div>
				</section>
				<img
					src={perks_glow}
					className="absolute z-[-1] lg:top-[490px] md:top-[550px] top-[580px] lg:right-[-130px] md:right-[-190px] right-[-160px]"
					alt=""
				/>
			</section>
		</>
	);
}
