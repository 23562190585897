import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Button from "../../components/Button";
import TimeIcon from "../../assets/time.svg";
import LocIcon from "../../assetss/loc.png";
import VideoIcon from "../../assetss/video.png";
import Listing from "../../components/Listing";
import { validateDateIsInPast } from "../../utils/ValidateDateIsPast";
import { Accordion } from "../../components/Accordion";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserLocation } from "../../services/LocationServices";

const Level = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userLocation = getUserLocation();
  const data = state && state.data;

  const goToSignup = () => {
    navigate("/Signup", { state: { data } });
  };

  return (
    <>
      <ScrollToTop />
      <section className="py-[140px] lg:py-[180px]">
        <section className="lg:px-[81px] md:px-[30px] px-[20px] mb-24">
          <section className="flex flex-col-reverse items-center md:grid lg:grid-cols-9 md:grid-cols-99 gap-5">
            <div>
              <p className="text-[#FFF] font-bold lg:text-5xl text-3xl mb-2">
                DevOps ({data.level} Level)
              </p>
              <ul className="text-[#A1A1AA] font-normal my-8">
                {data.successMetrics.map((metric) => (
                  <li>{metric}</li>
                ))}
              </ul>
              <Button
                handleClick={goToSignup}
                text="Apply Now"
                custom="bg-white text-black md:w-[269px] md:h-[58px]"
              />
            </div>
            <div className="md:flex justify-end">
              <div className="lg:w-[353px] md:w-[300px]">
                <img src={data.thumbnail} className="w-full" alt="student" />
              </div>
            </div>
          </section>
        </section>

        <section className="bg-black lg:px-[81px] pt-[20px] lg:py-[20px] md:px-[10px] text-center">
          <div className="md:grid md:grid-cols-4 grid grid-cols-2 justify-evenly md:mb-4 mb-7">
            <Listing
              upper="Next Cohort Date:"
              lower={validateDateIsInPast("2024-2-26")}
            />
            <Listing upper="Duration:" lower={data.duration} />
            <Listing
              upper="Tuition Fee:"
              lower={
                userLocation?.country_name !== "Nigeria"
                  ? `${data.dollarPrice}`
                  : `${data.nairaPrice}`
              }
            />
            <Listing upper="Learning Mode:" lower="Online Classes" />
          </div>
        </section>

        <section className="flex flex-col lg:px-[81px] md:px-[30px] px-[20px] mt-24">
          <div className="bg-white px-4 py-[1.2rem] rounded-t-lg font-medium">
            About this Course
          </div>
          <div className="pl-4 pr-16 pt-10 text-lg text-white">
            <p>{data.about}</p>
          </div>
        </section>

        <section className="flex flex-col lg:px-[81px] md:px-[30px] px-[20px] mt-20">
          <p className="flex items-center text-white text-lg font-bold bg-transparent ">
            Prerequisites
          </p>
          <div className="flex flex-col text-white mb-16 space-y-3 px-4 pt-7">
            <p className="flex items-center">
              <span className="mr-3">
                <img src={TimeIcon} alt="time icon" />
              </span>
              {data.duration}
            </p>
            <p className="flex items-center">
              <span className="mr-3">
                <img src={LocIcon} alt="location icon" />
              </span>
              Assisted Learning
            </p>
            <p className="flex items-center">
              <span className="mr-3">
                <img src={VideoIcon} alt="video icon" />
              </span>
              Live Quizzes
            </p>
          </div>

          <h2 className="font-bold text-lg my-10 text-white">Course Outline</h2>
          <Accordion data={data.curriculum} />
        </section>

        <div className="flex flex-col lg:px-[81px] md:px-[30px] px-[20px] mt-16">
          <Button
            handleClick={goToSignup}
            text="Apply Now"
            custom="bg-white text-black md:w-[269px] md:h-[58px]"
          />
        </div>
      </section>
    </>
  );
};

export default Level;
