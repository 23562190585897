import React from "react";
import LearningCard from "./LearningCard";
import python from "../../assetss/python.png";
import linux from "../../assetss/linux.png";
import jenkins from "../../assetss/jenkins.png";
import aws from "../../assetss/aws.png";
import docker from "../../assetss/docker.png";
import terraform from "../../assetss/terraform.png";

export default function Learn() {
  const LearningData = [
    {
      id: 0,
      name: "Introduction to Devops & Linux",
      icon: linux,
      sub: [
        "Introduction to Linux",
        "Virtualization",
        "Linux fundamentals and commands",
        "Version Control with Git",
        "Bash Scripting",
      ],
    },
    {
      id: 1,
      name: "Python",
      icon: python,
      sub: [
        "Introduction to Python",
        "Basic Syntax",
        "Data Structures",
        "Functions & Modules",
      ],
    },
    {
      id: 2,
      name: "Maven & Jenkins",
      icon: jenkins,
      sub: [
        "What is CICD",
        "Introduction to Maven & Jenkins",
        "Integrating Jenkins with AWS",
        "Jenkins Pipeline as a Code using Groovy scripts",
      ],
    },
    {
      id: 3,
      name: "AWS Services",
      icon: aws,
      sub: [
        "Introduction into storage systems: EBS, EFS, S3",
        "Networking - VPC",
        "Introduction to ELB &  ASG",
        "Introduction to Database - RDS, Aurora, DynamoDB",
      ],
    },
    {
      id: 4,
      name: "Containerization",
      icon: docker,
      sub: [
        "What is Containerisation",
        "What is Docker",
        "Containerisation with Docker",
        "Docker Compose",
      ],
    },
    {
      id: 5,
      name: "IaC with Terraform",
      icon: terraform,
      sub: [
        "Infrastructure as Code Concepts",
        "Terraform Configuration Language",
        "State Management",
        "Terraform Commands",
        "Variables, Output & Provisioning",
      ],
    },
  ];

  return (
    <>
      <section className="lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px] mt-5">
        <h2 className="text-white lg:text-[48px] md:text-[34px] text-[24px] font-bold md:mb-8 mb-6">
          Our DevOps Course Schedule
        </h2>
        <div className="md:grid lg:grid-cols-3 md:grid-cols-2 gap-10 justify-between mb-10">
          {LearningData.map((topic) => (
            <LearningCard
              name={topic.name}
              list={topic.sub}
              key={topic.id}
              icon={topic.icon}
            />
          ))}
        </div>
      </section>
    </>
  );
}
