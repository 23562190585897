import React from 'react'

export default function Box(props) {
  return (
        <div className='rounded-lg bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] p-[2px]' key={props.id}>
            <div className='bg-black p-5 text-left rounded-lg md:w-full lg:w-[280px] h-full'>
                <div className='w-[53px] h-[50px] mb-6'>
                    <img className='w-full h-full' src={props.icon} alt='' />
                </div>
                <p className='font-medium md:text-base text-sm leading-5 text-white mb-6'>{props.title}</p>
                <p className='font-normal md:text-base text-sm leading-5 text-[#A1A1AA]'>{props.description}</p>
            </div>
        </div>
  )
}
