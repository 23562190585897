import React from "react";

export default function LearningCard({ name, list, icon }) {
  return (
    <>
      <div className="p-[2px] bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] rounded-lg items-center align-middle grid md:my-0 my-3">
        <div className="lg:py-7 lg:px-10 md:p-6 p-7 bg-[#2A2145] rounded-lg text-white h-full">
          <div className="flex items-center mb-2">
            <div className="text-xs flex items-center mr-4 rounded-full p-3 shadow-inner border border-[#C2858A1A]">
              <div className="w-9 h-9">
                <img src={icon} className="w-full h-full" alt="icon" />
              </div>
            </div>
            <p>{name}</p>
          </div>
          <div className="text-[13px] translate-x-6">
            <ul className="list-disc">
              {list?.map((list, index) => (
                <li key={index}>{list}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

// md:h-[20vh] h-[40vh]
