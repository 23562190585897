import React from 'react'

export default function Main() {
  return (
    <>
    <section className='lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[140px] px-[20px] py-[100px]'>
        <article className='text-white mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>Privacy Policy</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>This privacy policy will help you understand how schull.io uses and protects the data you provide to us when you visit and use schull.io</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>
        </article>
        <article className='text-white mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>What User Data We Collect</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>When you visit our website, we may collect the following data:</p>
            <ul className='lg:text-[21px] md:text-[18px] text-[16px] font-normal list-disc translate-x-[24px]'>
                <li>Your IP address.</li>
                <li>To improve our services and products.</li>
                <li>Your contact information and email address.</li>
                <li>Other information such as interests and preferences.</li>
                <li>Data profile regarding your online behavior on our website.</li>
            </ul>
        </article>
        <article className='text-white mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>Why We Collect Your Data</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>We are collecting your data for several reasons:</p>
            <ul className='lg:text-[21px] md:text-[18px] text-[16px] font-normal list-disc translate-x-[24px]'>
                <li>To better understand your needs.</li>
                <li>To improve our services and products.</li>
                <li>To send you promotional emails containing the information we think you will find interesting.</li>
                <li>To contact you to fill out surveys and participate in other types of market research.</li>
                <li>To customize our website according to your online behavior and personal preferences.</li>
            </ul>
        </article>
        <article className='text-white  mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>Safeguarding and Securing the Data</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>Schull.io is committed to securing your data and keeping it confidential. Schull.io has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.</p>
        </article>
        <article className='text-white mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>Our Cookie Policy</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit).</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>If you want to disable cookies, you can do it by accessing the settings of your internet browser. You can visit schull.io which contains comprehensive information on how to do this on a wide variety of browsers and devices.</p>
        </article>
        <article className='text-white mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>Links to Other Websites</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>Our website contains links that lead to other websites. If you click on these links schull.io is not held responsible for your data and privacy protection. Visiting those websites is not governed by this priacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.</p>
        </article>
        <article className='text-white mb-8'>
            <p className='text-[#FDCA01] lg:text-[32px] md:text-[28px] text-[24px] mb-5 font-medium'>Restricting the Collection of your Personal Data</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>When you are filling the forms on the website, make sure to check if there is a box which you can leave unchecked, if you don't want to disclose your personal information.</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal mb-3'>If you have already agreed to share your information with us, feel free to contact us via email and we will be more than happy to change this for you.</p>
            <p className='lg:text-[21px] md:text-[18px] text-[16px] font-normal'>Schull.io will not lease, sell or distribute your personal information to any third parties, unless we have your permission. We might do so if the law forces us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy.</p>
        </article>
    </section>
    </>
  )
}







