import React from "react";

export default function TextInput({label, name, onChange, value, type, placeholder}) {
  return (
    <label>
      {label}: <br />
      <input
        className="w-full bg-black text-gray-500 lg:py-3 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none placeholder-gray-700"
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </label>
  );
}
