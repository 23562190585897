import React from "react";

export default function Button({ text, custom, handleClick }) {
  return (
    <button
      onClick={handleClick}
      className={`hover:cursor-pointer border duration-300 border-yellow-500 text-[#FFFFFF] font-medium lg:px-[60px] px-[20px] py-[15px] rounded-[50px] ${custom}`}
      type="submit"
    >
      {text}
    </button>
  );
}
