import React from "react";
import Hero from "./Hero";
import Career from "./Career";
import Slider from "./Slider";
import Alumni from "./Alumni";
import Learn from "./Learn";
import Perks from "./Perks";
import Number from "./Number";
import Faqs from "../Faq/Faqs";
import Newsletter from "./Newsletter";
import ScrollToTop from "../../components/ScrollToTop";
import GainExperience from "./GainExperience";
import Video from "./Video";
// import Roadmap from "./Roadmap";
// import Experience from "./Experience";
// import Webinar from "./Webinar";
// import Learnn from './Learnn'

export default function Page() {
  return (
    <>
      <ScrollToTop />
      <Hero />
      <GainExperience />
      {/* <Webinar /> */}
      {/* <Roadmap /> */}
      <Career />
      {/* <Experience /> */}
      <Slider />

      <Video />
      <Alumni />
      {/* <Learnn /> */}
      <Learn />
      <Perks />
      <Number />
      <Faqs />
      <Newsletter />
    </>
  );
}
