import { useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

const SubLevelComp = ({ item, renderNestedLevels }) => {
  const [selected, setSelected] = useState("");

  const toggle = () => {
    setSelected(selected === "" ? "active" : "");
  };

  const hasChildren = (item) => {
    return Array.isArray(item.children) && item.children.length > 0;
  };

  return (
    <div
      className={`text-white bg-transparent border-[#CBD5E1] ${
        item.type === "heading" ? "border-[1px] mb-6" : "border-0"
      }   `}
    >
      {item.type === "heading" ? (
        <p
          onClick={() => toggle()}
          className={`text-lg font-bold p-4 flex items-center justify-between w-full border-b-[1px] ${
            hasChildren(item) ? "cursor-pointer" : ""
          }`}
        >
          {item.title}
          {hasChildren(item) && (
            <span>{selected === "active" ? "-" : "+"}</span>
          )}
        </p>
      ) : (
        <p
          onClick={() => toggle()}
          className={`border-0  px-2 py-2.5 flex items-center 
            ${hasChildren(item) ? "cursor-pointer" : ""} ${
            selected ? "text-[#FDCA01]" : ""
          } `}
        >
          {hasChildren(item) && (
            <span className="mr-2">
              {selected === "active" ? <IoChevronUp /> : <IoChevronDown />}
            </span>
          )}
          {item.title}
        </p>
      )}

      {selected && (
        <div className="ml-2">
          {hasChildren(item) && renderNestedLevels(item.children)}
        </div>
      )}
    </div>
  );
};

export function Accordion({ data }) {
  const renderNestedLevels = (data) => {
    return data.map((item, itemIndex) => (
      <SubLevelComp
        item={item}
        renderNestedLevels={renderNestedLevels}
        key={itemIndex}
      />
    ));
  };

  return renderNestedLevels(data);
}
