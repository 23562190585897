import React from "react";
import announcement from "../../assetss/study_at_schulltech.png";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
// import ReactPlayer from "react-player";

const GainExperience = () => {
  return (
    <section>
      <div className="grid grid-cols-1 place-items-center lg:grid-cols-2 gap-8 lg:px-[81px] px-[20px] py-40">
        <div>
          <h4 className="gradient-text text-sm mb-2">WHY JOIN SCHULL.IO</h4>
          <h2 className="lg:text-5xl md:text-4xl text-3xl text-white font-semibold leading-relaxed mb-4">
            Gain Real-World Experience Through Project-Based Learning
          </h2>
          <p className="text-base lg:text-lg text-[#A1A1A1] leading-normal mb-8">
            At Schull.io, you are equipped with the best Tech knowledge and
            practices that will give you a voice anywhere in the world. With
            industry standard teaching, hand-on practicals, and internship
            placement opportunities, we are your preferred edu-tech platform.
          </p>
          <Link to="/Courses" className="links">
            <Button text="View Courses" custom="bg-[#FDCA01] text-black" />
          </Link>
        </div>
        <div className="relative">
          <img
            className="h-full w-full object-fill"
            src={announcement}
            alt="announcement"
          />
          {/* <ReactPlayer
            url="https://youtu.be/cSSd3C3oxyg"
            width="100%"
            controls={true}
          /> */}
        </div>
      </div>
      {/* <div className="grid md:grid-cols-2 lg:gap-20 gap-10 lg:px-[81px] px-[20px]">
        <div className="">
          <div className="lg:h-[700px] md:h-[400px] rounded-lg overflow-hidden">
            <img
              className="h-full w-full object-fill"
              src={announcement}
              alt="announcement"
            />
          </div>
        </div>
        <div className="">
          <div className="lg:h-[700px] md:h-[400px] rounded-lg overflow-hidden">
            <img
              className="h-full w-full object-fill"
              src={xmas}
              alt="announcement"
            />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default GainExperience;
