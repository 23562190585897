import React from 'react'

export default function ContactUs() {
  return (
    <>
        <div className='bg-black text-white text-center flex flex-col align-center m-auto justify-center items-center h-[318px]'>
                <h2 className='font-bold lg:text-[32px] text-lg'>Get in touch with us</h2>
                <p className='lg:w-[592px] md:w-[500px] p-5'>Contact us for more  enquiries. </p>
                <button className='border border-yellow-500 hover:bg-yellow-500 duration-300 hover:text-black text-white text-base font-medium px-5 py-2 rounded-full mt-5 mb-14'>Contact Us</button>
            </div>
    </>
  )
}
