import React from "react";
import Box from "./Box";
import square from "../../assetss/square.png";
import penta from "../../assetss/penta.png";
import circle from "../../assetss/circle.png";
import octa from "../../assetss/octa.png";
import perks_glow from "../../assetss/perks_glow.png";

export default function Perks() {
  const data = [
    {
      id: 0,
      icon: square,
      title: "Linux Fundametal and Fully Guided Labs ",
      description:
        "We will get you up to speed on Linux Fundamentals and DevOps concepts quickly, you will also be given the opportunity to implement DevOps concepts on real projects.",
    },
    {
      id: 1,
      icon: octa,
      title: "One-on-One Mentorship & A Triad System",
      description:
        "Support and Mentorship will be provided from experienced DevOps Engineers, also a Triad learning system will be introduced.",
    },
    {
      id: 2,
      icon: circle,
      title:
        "Employability Training (Agile, Softskills, and Interview Training)",
      description:
        "Employability training will be provided to include  a focus on Agile training and  soft skills.  You’ll also be equipped with a DevOps engineer resume   to use for prospective interviews.  There will also be prior and ongoing support even after you have landed a job.",
    },
    {
      id: 3,
      icon: penta,
      title: "Community of Support (Cloud Paddie) Coming soon..",
      description:
        "We understand that the job search process can be draining. To help you, our  CloudPaddie community serve you as a job board where highly trained and certified cloud engineering Schull.io alumni  are placed for full time or contract based job opportunities.",
    },
  ];

  const box = data.map((item, i) => {
    return <Box key={i} {...item} />;
  });

  return (
    <>
      <section className="text-center relative lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px] overflow-hidden">
        <div className="md:mb-36 mb-10 flex flex-col m-auto items-center">
          <h3 className="text-[#FDCA01] lg:text-5xl md:text-3xl text-2xl font-medium lg:leading-[74px] md:leading-[41px] mb-6 lg:w-[692px] md:w-[327px]">
            Perks <span className="text-white">of Signing up</span>
          </h3>
          <p className="text-[#A1A1AA] text-lg font-normal md:w-[700px] w-[308px]">
            At Schull.io, we'll prepare you for a fulfilling Cloud DevOps career
            with our two-in-one training and mentorship program. In 5-6 months,
            you will be ready to take on DevOps Engineer roles with confidence.
          </p>
        </div>
        <img src={perks_glow} className="absolute z-[-1]" alt="" />
        <img
          src={perks_glow}
          className="absolute z-[-1] right-3 bottom-5"
          alt=""
        />
        <div className="flex lg:flex-row flex-col gap-5 justify-evenly lg:px-0 md:px-[100px] px-[30px]">
          {box}
        </div>
      </section>
    </>
  );
}
