import React from 'react'
import map from '../../assetss/mappp.png'

export default function Alumni() {
  return (
    <>
        <section className='lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[20px]'>
            <p className='text-[#FDCA01] font-bold lg:text-xl text-lg leading-6 text-center mb-5'>BECOME AN ALUMNI</p>
            <p className='font-bold lg:text-[32px] text-2xl leading-8 text-center text-white mb-10'>Be a part of Schull.io Cloud DevOps Community and Discover  your Network </p>
            <div className='flex relative lg:h-[488px] text-center items-center align-middle justify-center m-auto'>
                <img className='lg:h-full' src={map} alt='' />
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:bottom-[220px] lg:left-[250px] md:bottom-[200px] md:left-[100px] bottom-[150px] left-[100px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:bottom-[280px] lg:left-[200px] md:bottom-[160px] md:left-[150px] bottom-[120px] left-[150px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:top-0 lg:right-[300px] md:top-0 md:right-[300px] top-3 right-[100px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:top-[100px] lg:left-[600px] md:top-[50px] md:left-[600px] top-[60px] left-[250px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:top-[80px] lg:right-[480px] md:top-[80px] md:right-[480px] top-[80px] right-[250px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:top-[160px] lg:left-[580px] md:top-[130px] md:left-[520px] top-[140px] left-[280px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:top-[190px] lg:right-[200px] md:top-[110px] md:right-[250px] top-[150px] right-[230px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:bottom-[50px] lg:right-[180px] md:bottom-[50px] md:right-[320px] bottom-[30px]'></div>
                <div className='map_pulse absolute w-[8px] h-[8px] bg-[#9E77ED] rounded-full lg:bottom-[20px] lg:right-[100px] md:bottom-[20px] md:right-[100px] bottom-[90px] right-[130px]'></div>
            </div>
        </section>
    </>
  )
}
