import React from "react";
import Main from "./Main";
import ScrollToTop from "../../components/ScrollToTop";

export default function Contact() {
  return (
    <>
      <ScrollToTop />
      <Main />
    </>
  );
}
