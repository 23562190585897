import React from "react";
import Main from "./Main";
import MainUS from "./MainUS";
import ScrollToTop from "../../components/ScrollToTop";
import { getUserLocation } from "../../services/LocationServices";

export default function Cost() {
  const userLocation = getUserLocation();

  return (
    <>
      <ScrollToTop />
      <div className="">
        {userLocation?.country_name !== "Nigeria" ? <MainUS /> : <Main />}
      </div>
    </>
  );
}
