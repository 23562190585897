import React from 'react'
import Main from './Main'
import ScrollToTop from '../../components/ScrollToTop'

export default function Policy() {
  return (
    <>
    <ScrollToTop/>
    <Main/>
    </>
  )
}
