import React from "react";
import arrow_r from "../../assetss/arrow_r.png";
import arrow_u from "../../assetss/arrow_u.png";
import ScrollToTop from "../../components/ScrollToTop";

export default function Faqs() {
  const faqs = [
    {
      questions: "What is Schull.io?",
      answers:
        "Schull.io is an ed-tech platform that specializes in developing and placing skilled individuals in Cloud Engineering, DevOps, Cybersecurity, Agile, and Integration Engineering to meet the global demand for highly qualified professionals in these fields. We offer virtual training  that helps you learn valuable skills that are relevant and valuable for the 21st century. Lastly, we also work with employers who want to hire candidates with digital skills through our cloud paddie community",
    },
    {
      questions: "What is the duration of the program?",
      answers:
        "The program is for 16 to 24 weeks. However, virtual classes are held twice a week.",
    },
    {
      questions:
        "Can I apply for the program if I’m not a graduate or if I’m a beginner?",
      answers:
        "Yes, you can. Anyone interested in learning new digital skills is welcome to apply for our program. To be considered for admission, you will be required to enroll for our program by making a registration payment and opt for any of our payment options.",
    },
    {
      questions:
        " Does Schull.io provide internship opportunities to help their talents gain real life experience?",
      answers:
        "Yes, we usually place Talents who have shown hunger to earn real life experience in our internship program for a period of 3 months with provision of  stipends to cover transportation and feeding if it’s a physical engagement or data expenses if it’s a remote engagement.",
    },
    {
      questions: "Can I cancel or apply for a refund?",
      answers:
        "If you have a cause to apply for a refund, the window period for this is within the first week of class (after the pre-course), you will receive a refund in the amount less the application processing fee.",
    },
    {
      questions: "Must I be a Nigerian citizen to apply?",
      answers:
        "No, We have a target to  build a community of talented professionals based in Nigeria and Sub-Saharan Africa. We also have graduates working in other countries beyond Nigeria. We believe that knowledge and opportunities can be equally distributed. ",
    },
    {
      questions: "I work full time. What options do I have?",
      answers:
        "However, the program isn’t on a self-paced model, we have recordings for live sessions to help you catch up. We however emphasize that our  talents  make at least 85% of our virtual led classes so that you have the chance to ask your tutor questions and get answers in real time.",
    },
    {
      questions:
        "Will a certificate of completion be issued after successful completion of the program?",
      answers:
        "A Blockchain enabled certificate of completion is awarded after successful completion of the program by our Talents. ",
    },
    {
      questions: "Is a job guaranteed for me after training?",
      answers:
        "We don't promise our Talents jobs. Our commitment  is to make you employable and connect you with employers. We will work with you to build a great portfolio that will give you the confidence to apply to your dream job through our Cloudpaddie platform. We will also provide you with career advisory services that include building a resume review, online profile review, interview preparation, etc.",
    },
    {
      questions: "How flexible is the program?",
      answers:
        "Since our classes are virtual which does not require you  to be physically present, it is somewhat flexible. However, there are clear schedules to follow, starting with classes holding twice in a week. Also, the class is focused on hands-on learning through projects. Thus, you will actively work on said projects on non-class days.",
    },
  ];

  const [selected, setSelected] = React.useState(null);

  function toggle(i) {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  }

  const item = faqs.map((item, i) => {
    return (
      <div key={i} className="item w-full">
        <div
          className="title flex justify-between items-center cursor-pointer border p-5"
          onClick={() => toggle(i)}
        >
          <h3 className="lg:text-[24px] text-[17px] md:w-auto w-[90%]  font-medium text-white">
            {item.questions}
          </h3>
          <div className="w-[20px]">
            <img
              className="w-full"
              src={selected === i ? arrow_u : arrow_r}
              alt=""
            />
          </div>
        </div>
        <div
          className={
            selected === i
              ? `content h-auto max-h-[999px] duration-300 p-5`
              : `content max-h-0 overflow-hidden duration-300 px-5`
          }
        >
          <p className="text-[#D4D4D8] lg:text-[20px] font-normal">
            {item.answers}
          </p>
        </div>
      </div>
    );
  });
  return (
    <>
      <ScrollToTop />
      <section className="lg:px-[81px] lg:py-[130px] md:px-[30px] md:py-[150px] px-[20px] py-[130px] bg-black mb-20">
        <div className="bg-gradient-to-r from-[#6DDCFF] to-[#7F60F9] pt-0 px-0 pb-[1px] mb-5">
          <div className="bg-black">
            <h5 className="text-sm gradient-text mb-8">FAQ</h5>
            <h3 className="lg:text-6xl md:text-[45px] text-3xl text-white max-w-4x pb-10 lg:pb-20">
              Answers to our frequently asked questions
            </h3>
          </div>
        </div>
        <div className="">{item}</div>
      </section>
    </>
  );
}
