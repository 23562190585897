import React from 'react'
import Navbar from '../components/Navbar2'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'

export default function HeaderFooterLayout() {
  return (
    <>
        <Navbar />
        {/* {children} */}
        <Outlet/>
        <Footer />
    </>
  )
}

