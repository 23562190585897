import axios from "axios";

export const fetchUserLocation = async () => {
  try {
    const response = await axios.get(`https://ipapi.co/json`);
    console.log(response.data.country_name);
    setUserLocation(response.data);
  } catch (error) {
    console.log(error);
  }
};

export const setUserLocation = (location) => {
  localStorage.setItem("location", JSON.stringify(location));
};

export const getUserLocation = () => {
  const location = localStorage.getItem("location");
  if (location === "undefined" || !location) {
    return null;
  } else {
    return JSON.parse(location);
  }
};
