import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import CourseCard from "../../components/CourseCard";
import { devOpsCurriculum } from "../../utils/curriculumData";

const DevOps = () => {
  return (
    <>
      <ScrollToTop />
      <section className="py-[200px] md:py-[180px] lg:py-[200px] lg:px-[81px] md:px-[30px] px-[20px]">
        <div className="flex flex-col space-y-4">
          <h1 className="text-[#FDCA01] font-bold text-[3.5rem]">
            DevOps Curriculum
          </h1>
          <p className=" text-[#A1A1AA]">
            DevOps is a movement that embraces collaboration and cooperation
            among systems and application developers, system administrators, and
            engineers.
          </p>
        </div>

        <div className="flex flex-col space-y-[6rem] mt-20">
          {devOpsCurriculum.map((item) => (
            <CourseCard key={item.level} course={item} />
          ))}
        </div>
      </section>
    </>
  );
};

export default DevOps;
