import foundationalThumbnail from "../assetss/foundationalthumbnail.png";
import advancedThumbnail from "../assetss/advancedthumbnail.png";

export const devOpsFundamentalCurriculum = [
  {
    id: 1,
    type: "heading",
    title: "Linux Fundamentals",
    children: [
      {
        id: "week1",
        title: "Week 1",
        children: [
          {
            id: "week1-topic1",
            title:
              "Tools Prerequisite Information, Chocolatey for Windows, Homebrew for macOS, Installing Software, Tools Prerequisites for Ubuntu 20, Signups, AWS Setup",
          },
          {
            id: "week1-topic2",
            title:
              "Introduction to Virtualisation Technology, What is Virtualization, Introduction VM-Manually(Windows & macOS Intel chip), VM-Automatically (Windows & macOS Intel chip), VM on macOS M1 chip",
          },
        ],
      },
      {
        id: "week2",
        title: "Week 2",
        children: [
          {
            id: "week2-topic1",
            title: "Linux fundamentals and commands",
          },
          {
            id: "week2-topic2",
            title: "Working with the Linux file system hierarchy ",
          },
        ],
      },
      {
        id: "week3",
        title: "Week 3",
        children: [
          {
            id: "week3-topic1",
            title: "Linux user and group management, Configuring Permissions ",
          },
          {
            id: "week3-topic2",
            title:
              "Bash Scripting in AWS, Bash Scripting in Linux/ Git & GitHub Workflow for Shell Scripts",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    type: "heading",
    title: "Amazon Web Services",
    children: [
      {
        id: "week4",
        title: "Week 4",
        children: [
          {
            id: "week4-topic1",
            title:
              "IAM - Introduction, Core Components, Types of Policies, Policy Structure, Password Policy, Access Keys, Multi-Factor Authentication, Cheat Sheet",
          },
          {
            id: "week4-topic2",
            title:
              "Cloud Trail - Introduction, overview of Management vs Data events, Cloud trail insights",
          },
          {
            id: "week4-topic3",
            title:
              "Cloudwatch - Introduction, Custom metric, Cloudwatch dashboard, Cloudwatch alarm, Cloudwatch metric, Cloudwatch logs, Cloudwatch event",
          },
        ],
      },
      {
        id: "week5",
        title: "Week 5",
        children: [
          {
            id: "week5-topic1",
            title:
              "EC2 - Introduction, Instance Types, Instance Sizes, Instance Profiles, Placement Groups, Userdata, Metadata",
          },
          {
            id: "week5-topic2",
            title:
              "Pricing -  Introduction, On-Demand Instances, Reserved Instances, Spot Instances, Dedicated Host Instances",
          },
          {
            id: "week5-topic3",
            title:
              "AMI - Introduction, Use Cases, Marketplace, Create an AMI, Choosing an AMI, Copying an AMI",
          },
        ],
      },
      {
        id: "week6",
        title: "Week 6",
        children: [
          {
            id: "week6-topic1",
            title: "Security Groups - Introduction, Use Cases, Limits",
          },
          {
            id: "week6-topic2",
            title:
              "AWS CLI & SDK - Command Line Interface (CLI), Software Development Kit (SDK), Programmatic Access, Cloud 9 and CLI Setup",
          },
        ],
      },
      {
        id: "week7",
        title: "Week 7",
        children: [
          {
            id: "week7-topic1",
            title:
              "Introduction to Elastic Block Store (EBS), Volume Types, EBS vs Instance Store Volumes",
          },
          {
            id: "week7-topic2",
            title: "Introduction to Elastic File System (EFS), Mounting EFS",
          },
          {
            id: "week7-topic3",
            title:
              "S3 - Introduction, Storage Classes, Storage Class Comparison, Security, Encryption, Data Consistency, Cross-Region Replication, Versioning, Lifecycle Management, Transfer Acceleration, Pre Signed URLs, MFA Delete",
          },
        ],
      },
      {
        id: "week8",
        title: "Week 8",
        children: [
          {
            id: "week8-topic1",
            title:
              "Introduction to Elastic Load Balancing, Rules of Traffic, Application Load Balancer (ALB), Network Load Balancer (NLB), Classic Load Balancer (CLB), Sticky Sessions",
          },
          {
            id: "week8-topic2",
            title:
              "Introduction to Auto Scaling Groups, Capacity Settings, Health Check Replacements, Scaling Policies, ELB Integration",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    type: "heading",
    title: "Programming",
    children: [
      {
        id: "week9",
        title: "Week 9",
        children: [
          {
            id: "week9-topic1",
            title:
              "Introduction to Python, Strings, input, float, tuples, dictionary, booleans, Extended function, If statements, Functions, Exponent Functions, Inheritance",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    type: "heading",
    title: "DevOps Tools",
    children: [
      {
        id: "week10",
        title: "Week 10",
        children: [
          {
            id: "week10-topic1",
            title: "Introduction: Containers & Docker, Building Docker Images",
          },
        ],
      },
      {
        id: "week11",
        title: "Week 11",
        children: [
          {
            id: "week11-topic1",
            title:
              "Introduction to Maven, Jenkins, What is CICD, Setup Jenkins Server (Cloud/Local/Container)",
          },
        ],
      },
      {
        id: "week12",
        title: "Week 12",
        children: [
          {
            id: "week12-topic1",
            title:
              "Introduction to Cloudformation, Quick start, Template anatomy, Template format",
          },
          {
            id: "week12-topic2",
            title: "Introduction to Terraform",
          },
        ],
      },
    ],
  },
];

export const devOpsAdvancedCurriculum = [
  {
    id: 1,
    type: "heading",
    title: "Continuous Integration and Deployment",
    children: [
      {
        id: "week1",
        title: "Week 1",
        children: [
          {
            id: "week1-topic1",
            title:
              "Advanced Git and Version Control - In-depth usage of Git, branching strategies, and complex workflows.",
          },
        ],
      },
      {
        id: "week2",
        title: "Week 2",
        children: [
          {
            id: "week2-topic1",
            title: "Jenkins Pipeline as a Code using Groovy scripts",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    type: "heading",
    title: "DevOps Tools",
    children: [
      {
        id: "week3",
        title: "Week 3",
        children: [
          {
            id: "week3-topic1",
            title:
              "Introduction to Container Orchestration, Kubernetes Architecture, Minikube & Kops for Kubernetes Cluster",
          },
        ],
      },
      {
        id: "week4",
        title: "Week 4",
        children: [
          {
            id: "week4-topic1",
            title:
              "Terraform - State Management, Terraform Commands, Variables, Output & Provisioning",
          },
        ],
      },
      {
        id: "week5",
        title: "Week 5",
        children: [
          {
            id: "week5-topic1",
            title:
              "Introduction to Ansible, Installing Ansible, Ansible managed environment, Adhoc commands, Getting started with playbooks, Using Ansible roles",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    type: "heading",
    title: "Amazon Web Services",
    children: [
      {
        id: "week6",
        title: "Week 6",
        children: [
          {
            id: "week6-topic1",
            title:
              "Route53 - Introduction, Domain Registrars, Top-Level Domains, Start of Authority (SOA)",
          },
          {
            id: "week6-topic2",
            title:
              "A Records, CNAME Records, NS Records, Time To Live (TTL), Routing Policies",
          },
        ],
      },
      {
        id: "week7",
        title: "Week 7",
        children: [
          {
            id: "week7-topic1",
            title:
              "Elastic Beanstalk - Introduction, Create a web app with Elastic Beanstalk",
          },
          {
            id: "week7-topic2",
            title:
              "API Gateway - Introduction, Key features, Configuration, Caching, Cross Origin Resource sharing (CORS), Same origin policy",
          },
        ],
      },
      {
        id: "week8",
        title: "Week 8",
        children: [
          {
            id: "week8-topic1",
            title:
              "AWS Lambda - Introduction, Versions, Alias, Throttling, Destinations",
          },
        ],
      },
      {
        id: "week9",
        title: "Week 9",
        children: [
          {
            id: "week9-topic1",
            title:
              "SQS - Introduction, Queue types, Short vs long pulling, Limit retention, Visibility timeout",
          },
          {
            id: "week9-topic2",
            title: "SNS - Introduction, Subscribers and Producers, SNS Topics",
          },
          {
            id: "week9-topic3",
            title:
              "Kinesis - Introduction, Video-stream, Data-stream, Data analytics, Data FireHose",
          },
        ],
      },
      {
        id: "week10",
        title: "Week 10",
        children: [
          {
            id: "week10-topic1",
            title:
              "Key Management System - KMS, Secret Manager, SSM Parameter Store",
          },
          {
            id: "week10-topic2",
            title:
              "VPC - Introduction, Core Components, Key Features, Default VPC, NACL, VPC Endpoints, VPC Flow Logs",
          },
        ],
      },
      {
        id: "week11",
        title: "Week 11",
        children: [
          {
            id: "week11-topic1",
            title:
              "Introduction to Database, Introduction to RDS, RDS Backup/Restoring backup, Snapshots, Multi-AZ, Read Replicas, Encryption ",
          },
          {
            id: "week11-topic2",
            title:
              "Aurora - Introduction, Replicas, Availability, Scaling, Serverless",
          },
          {
            id: "week11-topic3",
            title:
              "DynamoDB - Introduction, Table structure, Consistent Reads, DAX",
          },
          {
            id: "week11-topic4",
            title:
              "Redshift - Introduction, Redshift spectrum, Capability, Security",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    type: "heading",
    title: "Others",
    children: [
      {
        id: "week12",
        title: "Week 12",
        children: [
          {
            id: "week12-topic1",
            title: "Prometheus",
          },
          {
            id: "week12-topic2",
            title: "Gitops with AgroCD",
          },
        ],
      },
      {
        id: "bonus",
        title: "Bonus Lecture",
        children: [
          {
            id: "bonus-topic1",
            title:
              "Soft Skills - Time management, Communication Skills, and Emotional Intelligence",
          },
        ],
      },
    ],
  },
];

export const devOpsCurriculum = [
  {
    level: "Foundational",
    duration: "3 months",
    courseName: "DevOps Foundational",
    about:
      "The Foundation Courses in the Schull.io DevOps curriculum are designed to provide a comprehensive introduction to the essential principles, tools, and practices that form the backbone of DevOps. These courses are tailored for beginners and those who seek to solidify their understanding of the fundamental aspects of DevOps, enabling them to build a strong foundation for further learning and professional development in this dynamic field. Each course is structured to provide a blend of theoretical knowledge and practical skills, ensuring that students not only understand the concepts but also know how to apply them in real-world scenarios. By the end of these foundation courses, students will be well-equipped with the essential knowledge and skills required to embark on their DevOps journey and tackle more advanced topics with confidence.",
    successMetrics: [
      "You’ll Learn about AWS Cloud, Linux, Git, Containers, and Orchestration.",
      "You’ll be ready for certification in AWS and Linux Foundations.",
      "You’ll be qualified to apply for jobs like Cloud Support Specialist or Junior DevOps Engineer.",
    ],
    link: "/course/foundational",
    nairaPrice: "N150,000",
    dollarPrice: "$100",
    curriculum: devOpsFundamentalCurriculum,
    thumbnail: foundationalThumbnail,
    courses: [
      {
        title: "Introduction to DevOps",
        description: "Basic concepts, history, and principles of DevOps.",
      },
      {
        title: "Version Control with Git",
        description:
          "Fundamentals of version control and using Git for collaboration.",
      },
      {
        title: "Continuous Integration Fundamentals",
        description:
          "Basics of CI, setting up a CI server, and integrating with version control.",
      },
      {
        title: "Basic Scripting and Automation",
        description:
          "Introduction to scripting languages (like Bash, Python) and automation basics.",
      },
      {
        title: "Infrastructure as Code (IaC) Basics",
        description:
          "Introduction to IaC concepts and basic tools like Terraform or CloudFormation.",
      },
      {
        title: "Containerization with Docker",
        description:
          "Basics of containerization and using Docker for application deployment.",
      },
      {
        title: "Monitoring and Logging Basics",
        description:
          "Introduction to monitoring and logging tools and practices.",
      },
      {
        title: "Cloud Computing Fundamentals",
        description:
          "Basic concepts of cloud computing and an overview of  AWS cloud platform.",
      },
    ],
  },
  {
    level: "Advanced",
    about:
      "The Advanced Courses in the Schull.io DevOps curriculum are designed for professionals who have a foundational understanding of DevOps and are looking to deepen their expertise and specialize in advanced areas. These courses delve into the intricate aspects of DevOps, offering in-depth knowledge and hands-on experience with sophisticated tools, practices, and methodologies that are crucial for mastering DevOps at an expert level. Each advanced course is meticulously structured to provide an immersive learning experience, combining comprehensive theoretical knowledge with practical, hands-on applications. By the end of these masterclass courses, students will possess the advanced skills and expertise needed to excel in the most challenging and specialized areas of DevOps, positioning themselves as leaders and innovators in the field.",
    duration: "3 months",
    courseName: "DevOps Advanced",
    successMetrics: [
      "You’ll learn all about Jenkins, CI/CD, Terraform, and Kubernetes.",
      "Follow along on numerous hands-on projects",
      "Work on a Capstone project.",
      "You’ll be qualified for jobs like DevOps Engineer, Automation Engineer, or Cloud Architect.",
    ],
    link: "/course/advanced",
    nairaPrice: "N350,000",
    dollarPrice: "$100",
    curriculum: devOpsAdvancedCurriculum,
    thumbnail: advancedThumbnail,
    courses: [
      {
        title: "Advanced Git and Version Control",
        description:
          "In-depth usage of Git, branching strategies, and complex workflows.",
      },
      {
        title: "Continuous Delivery and Deployment",
        description:
          "Advanced CD practices, automated deployments, and release strategies.",
      },
      {
        title: "Advanced Automation and Scripting",
        description:
          " Complex scripting techniques, automation frameworks, and best practices.",
      },
      {
        title: "Advanced Infrastructure as Code",
        description:
          "Detailed study of IaC tools, advanced configurations, and real-world scenarios.",
      },
      {
        title: "Kubernetes and Container Orchestration",
        description:
          "Deep dive into Kubernetes, container orchestration, and scaling applications.",
      },
      {
        title: "DevSecOps",
        description:
          "Integrating security into DevOps practices, tools, and methodologies.",
      },
      {
        title: "Cloud Native Development",
        description:
          "Advanced cloud concepts, serverless computing, and cloud-native application development.",
      },
      {
        title: "Microservices Architecture",
        description:
          "Designing, deploying, and managing microservices-based applications.",
      },
      {
        title: "Advanced Monitoring and Observability",
        description:
          "In-depth monitoring, observability strategies, and using advanced tools.",
      },
    ],
  },
];

export const courseNamesAndPrices = [
  { name: "DevOps Advanced", price: 350000 },
  { name: "DevOps Foundational", price: 150000 },
  { name: "DevOps Full", price: 480000 },
  { name: "Solutions Architect", price: 170000 },
  { name: "Cloud Practitioner", price: 120000 },
];
