import React, { useState } from 'react'
import axios from "axios";

export default function Newsletter() {
    const [formData, setFormData] = useState({ email: "" })
    const [errorMessage, setErrorMessage] = useState(null);


    function handleChange(e) {
        e.preventDefault();

        const { name, value } = e.target
        setFormData(prevState => ({
            [name]: value
        }))
    }
    function handleClick(e) {
        e.preventDefault()
        setErrorMessage(null);
        if (
            formData.email === ""
            // setErrorMessage("Message sent successfully")
        ) {
            // setErrorMessage("Please fill all fields");
            return;
        }
        axios
            .post(mailingAPI, mailingData)
            .then((response) => {
                // console.log(response);
                setErrorMessage("Thank you for signing up for our newsletters.")
                setFormData({ email: '' })
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const mailingAPI = "https://r150n7ncna.execute-api.us-east-1.amazonaws.com/prod/mailing";

    const mailingData = {
        config: {
            fromEmailAddress: "schull.io@schull.io",
            toEmailAddress: "training@Schull.io",
        },
        body: {
            subject: "Newsletter",
            message: `<strong>Email:</strong> ${formData.email}<br>`,
        },
    };

    return (
        <>
            <section className='lg:px-[81px] lg:py-[54px] md:px-[30px] md:py-[40px] px-[20px] py-[30px] bg-[#000000] mb-14'>
                <div className='grid lg:grid-cols-2'>
                    <div className='lg:mb-0 mb-5'>
                        <p className='text-white font-medium text-base mb-3'>Newsletter</p>
                        <p className='text-[#A1A1AA] font-normal'>Be the first one to know  about discounts, offers and events</p>
                    </div>
                    <div className=''>
                        <form className="relative">
                            <input
                                className="bg-[#4B5768] w-full px-10 py-5 rounded-full text-white outline-none"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                            />
                            <button onClick={handleClick} className="text-black bg-[#FDCA01] absolute top-2 right-3 py-3 px-5 rounded-full">Submit</button>
                        </form>
                        <p className="self-end text-white font-light text-right text-sm mt-[1px] ">
                            {errorMessage}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}
