import React, { useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import perks_glow from "../../assetss/perks_glow.png";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import data from "../../assets/countrydata.json";
import Modal from "../../components/Modal";
import { PaystackButton } from "react-paystack";
import { courseNamesAndPrices } from "../../utils/curriculumData";
import { sendEmailsIteratively } from "./sendMail";
import { getUserLocation } from "../../services/LocationServices";

const track = ["Cohort 8 (27th May , 2024)"];
const age = ["Under 18", "18-24", "25-29", "30-34", "35 and above"];
const education = [
  "Secondary/High School",
  "OND/HND",
  "Undergraduate",
  "Graduate",
  "Postgraduate",
  "MBA",
  "Other",
];
const payStackKey = process.env.REACT_APP_PAYSTACK_KEY;
export default function Main() {
  const { state } = useLocation();
  const userLocation = getUserLocation();
  const courseData = state?.data ?? null;
  console.log(courseData);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showFailedModal, setShowFailedModal] = React.useState(false);
  const [showLoadingModal, setShowLoadingModal] = React.useState(false);
  const [country, setCountry] = React.useState(data.countries);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [whatsapp, setWhatsapp] = React.useState();
  const [phone, setPhone] = React.useState();
  const [paymentAmount, setPaymentAmount] = React.useState(15000);
  const courseNames = courseNamesAndPrices.map((item) => item.name);
  const [formData, setFormData] = React.useState({
    firstname: "",
    lastname: "",
    track: "",
    email: "",
    country: "",
    state: "",
    age: "",
    gender: "",
    education: "",
    course: courseData?.courseName,
    consent: false,
  });

  const componentProps = {
    email: formData.email,
    amount: paymentAmount * 100,
    metadata: {
      name: formData.firstname,
      phone: phone,
    },
    publicKey: payStackKey,
    text: "Register",
    onSuccess: () => {
      setShowLoadingModal(true);
      handleSendMail();
    },
    onClose: () => {
      setShowFailedModal(true);
      resetStates();
    },
  };

  // const componentProps = {
  //   email: "oalhazan@schulltech.com",
  //   amount: 10000,
  //   publicKey: payStackKey,
  //   text: "Testing Sub Account",
  //   split_code: "SPL_b8RKplO1Bi",
  // };

  const selectedCountryData = country.find(
    (country) => country.country === formData.country
  );
  const statesForSelectedCountry = selectedCountryData
    ? selectedCountryData.states
    : [];
  const countryNames = country.map((item, index) => {
    return (
      <option key={index} value={item.country}>
        {item.country}
      </option>
    );
  });

  const stateNames = statesForSelectedCountry.map((state) => (
    <option key={state} value={state}>
      {state}
    </option>
  ));

  function handleChange(e) {
    const { value, name, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  useEffect(() => {
    const selectedCourse = courseNamesAndPrices.find(
      (course) => course.name === formData?.course
    );
    const coursePrice = selectedCourse?.price ?? 0;
    setPaymentAmount(paymentAmount + coursePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.course]);

  async function handleSendMail() {
    const emails = [
      "osholashittu@schulltech.com",
      "oabiodun@schulltech.com",
      "sonigbinde@schulltech.com",
      "admin@schull.io",
    ];
    const mailBody = {
      subject: "Registration Form",
      message: `<strong>Name: ${formData.firstname} ${
        formData.lastname
      }</strong><br>
        <strong>Gender: ${formData.gender}</strong><br>
        <strong>Age: ${formData.age}</strong><br>
        <strong>Email:</strong> ${formData.email}<br>
        <strong>Track: ${formData.track}</strong><br>
        <strong>Paid for: Registration  ${formData.course && "and "} ${
        formData.course
      }</strong><br>
        <strong>Education: ${formData.education}</strong><br>
        <strong>Country: ${formData.country}</strong><br>
        <strong>State: ${formData.state}</strong><br>
        <strong>Agreed to Terms and Condition:${formData.consent}</strong><br>
        <strong>Phone:</strong>${phone}<br>
        <strong>Whatsapp:</strong>${whatsapp}`,
    };
    sendEmailsIteratively(emails, mailBody)
      .then(() => {
        console.log("All emails sent");
        setShowLoadingModal(false);
        setShowSuccessModal(true);
        resetStates();
      })
      .catch((errors) => {
        console.error("Errors occurred while sending emails:", errors);
        setShowLoadingModal(false);
        setErrorMessage(
          "Your payment was successful, but we encountered an issue while saving the payment details. Please contact our support team at admin@schull.io for assistance"
        );
        resetStates();
        setShowFailedModal(true);
      });
  }

  const resetStates = () => {
    setFormData({
      firstname: "",
      lastname: "",
      track: "",
      email: "",
      country: "",
      state: "",
      age: "",
      gender: "",
      education: "",
      course: "",
      consent: false,
    });
    setPhone();
    setWhatsapp();
    setPaymentAmount(15000);
  };

  return (
    <section className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[240px] px-[20px] py-[180px] relative overflow-hidden">
      <img
        src={perks_glow}
        className="absolute z-[-1] lg:top-[20px] md:top-[20px] top-[20px] lg:left-[30px] md:left-[30px] left-[20px]"
        alt=""
      />
      <div className="lg:w-[736px] mx-auto text-white mb-14">
        <p className="text-[#FDCA01] leading-[40px] md:text-[32px] text-[24px] font-medium inline">
          Register for{" "}
          {formData.course ? formData.course : "our Cloud Programs"}
        </p>
        <section className="md:flex justify-between gap-10 items-center mt-5">
          <div className="bg-[#2A2145] border border-slate-500 rounded-2xl py-6 p-4 md:mb-0 mb-5">
            <p className="lg:text-[18px]">A Non-refundable Registration Fee</p>
            <p className="lg:text-[36px] text-center">
              {userLocation?.country_name !== "Nigeria" ? "$9.00" : `₦15,000`}
            </p>
          </div>
          <div className="lg:text-[18px] md:w-1/2">
            <p className="">The Registration Fee</p>
            <ul className="text-[#FDCA01] list-disc ml-7">
              <li>Secures your slot in our programme</li>
              <li>
                Gives you access to our LMS portal which is activated on payment
                of the tuition fee.
              </li>
            </ul>
          </div>
        </section>
      </div>
      <div className="lg:w-[736px] mx-auto text-white bg-[#141021] md:p-8 p-4 border border-slate-500 rounded-2xl">
        <section className="">
          <h3 className="text-lg text-[#F6BE2C] font-bold mb-5">
            Fill in details before payment
          </h3>
          <div className="mb-5">
            <SelectInput
              name="course"
              label="Select Course/Programme"
              options={courseNames}
              value={formData.course}
              disabled={courseData ? true : false}
              handleChange={handleChange}
            />
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <TextInput
              label="First Name"
              name="firstname"
              onChange={handleChange}
              type="text"
              value={formData.firstname}
              placeholder="John"
            />
            <TextInput
              label="Last Name"
              name="lastname"
              onChange={handleChange}
              type="text"
              value={formData.lastname}
              placeholder="Doe"
            />
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <SelectInput
              label="What cohort are you going for?"
              name="track"
              handleChange={handleChange}
              value={formData.track}
              options={track}
            />
            <TextInput
              label="Email Address"
              name="email"
              onChange={handleChange}
              type="email"
              value={formData.email}
              placeholder="example@example.com"
            />
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <label>
              Phone Number <br />
              <PhoneInput
                defaultCountry="US"
                name="phone"
                placeholder="Enter your phone number"
                className="w-full bg-black text-gray-500 lg:py-3 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none placeholder-gray-700"
                onChange={setPhone}
                value={phone}
              />
            </label>
            <label>
              Whatsapp Number <br />
              <PhoneInput
                defaultCountry="US"
                type="tel"
                name="whatsapp"
                placeholder="Enter your WhatsApp number"
                className="w-full bg-black text-gray-500 lg:py-3 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none placeholder-gray-700"
                onChange={setWhatsapp}
                value={whatsapp}
              />
            </label>
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <label>
              Country <br />
              <select
                className="w-full bg-black text-gray-500 lg:py-3 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
                name="country"
                id="country"
                onChange={handleChange}
                value={formData.country}
              >
                <option value="">-Select-</option>
                {countryNames}
              </select>
            </label>
            <label>
              State <br />
              <select
                className="w-full bg-black text-gray-500 lg:py-3 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
                name="state"
                id="state"
                onChange={handleChange}
                value={formData.state}
              >
                <option value="">-Select-</option>
                {stateNames}
              </select>
            </label>
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-5">
            <SelectInput
              label="Select Age Range"
              name="age"
              handleChange={handleChange}
              value={formData.age}
              options={age}
            />
            <SelectInput
              label="Gender"
              name="gender"
              handleChange={handleChange}
              value={formData.gender}
              options={["Male", "Female"]}
            />
          </div>
          <div className="grid md:grid-cols-2 grid-rows-1 gap-5 mb-10">
            <SelectInput
              label="Educational Background"
              name="education"
              handleChange={handleChange}
              value={formData.education}
              options={education}
            />
          </div>
          <p className="self-end font-light text-right text-sm mt-[1px] ">
            {errorMessage}
          </p>
          <div className="flex items-center">
            <input
              className="cursor-pointer"
              type="checkbox"
              name="consent"
              id="consent"
              checked={formData.consent}
              onChange={handleChange}
            />
            <label htmlFor="consent">
              <span className="ml-2">
                I accept the{" "}
                <Link to="/Terms&Condition" className="links">
                  <span className="text-[#FDCA01] underline underline-offset-4">
                    Terms and Conditions
                  </span>
                </Link>
              </span>
            </label>
          </div>
        </section>
        {formData.consent &&
        formData.course !== "" &&
        formData.name !== "" &&
        formData.firstname !== "" &&
        formData.lastname !== "" &&
        formData.country !== "" &&
        formData.education !== "" &&
        formData.age !== "" &&
        formData.gender !== "" &&
        whatsapp !== undefined &&
        phone !== undefined ? (
          <div className="flex justify-center mt-10">
            <PaystackButton
              className="bg-yellow-500 text-black hover:cursor-pointer border duration-300 border-yellow-500 font-medium lg:px-[60px] px-[30px] py-[10px] rounded-[50px]"
              {...componentProps}
            />
          </div>
        ) : (
          <div className="flex justify-center mt-10">
            <button
              className="border border-gray-600 bg-slate-600 text-gray-800 font-medium lg:px-[60px] px-[30px] py-[10px] rounded-[50px]"
              disabled
            >
              Register
            </button>
          </div>
        )}
      </div>
      {/* <Modal /> */}
      {showSuccessModal && (
        <>
          <Modal
            text="Your payment has been received"
            text2="You would receive an email confirming your registration and payment in 24-48 hours"
            handlClick={() => {
              setShowSuccessModal(false);
            }}
          />
        </>
      )}
      {showFailedModal && (
        <>
          <Modal
            text="Your payment has not been received yet"
            text2={
              errorMessage ??
              "Please reach out to admin@schull.io to lodge a complaint"
            }
            handlClick={() => {
              setShowFailedModal(false);
            }}
          />
        </>
      )}
      {showLoadingModal && (
        <>
          <Modal
            text="Processing..."
            text2="Please wait while we confirm your registration"
            handlClick={() => {
              setShowLoadingModal(false);
            }}
          />
        </>
      )}
    </section>
  );
}
