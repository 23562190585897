import React from "react";
import curriculum from "../../assetss/girlcurriculum.jpg";
import video from "../../assetss/video1.png";
import media from "../../assetss/video.png";
import loc from "../../assetss/loc.png";
import foundation from "../../assetss/foundation.png";

export default function Main() {
  const Outline = [
    {
      id: 0,
      questions: "Linux",
      answers: [
        "Introduction to Virtualisation Technology",
        "Introduction to the Command Line",
        "File system hierarchy",
        "Commands",
        "Text Editor",
        "Bash Scripting",
        "SSH",
      ],
    },
    {
      id: 1,
      questions: "Containerisation",
      answers: [
        "What is Containerisation",
        "What is Docker",
        "Containerisation with Docker",
        "Docker Compose",
      ],
    },
    {
      id: 2,
      questions: "Cloud Computing with AWS",
      answers: [
        "What is Cloud Computing?",
        "IAM",
        "KMS",
        "EC2",
        "AWS CLI&SDK",
        "S3",
        "ELB",
        "Etc..",
      ],
    },
    {
      id: 3,
      questions: "Git",
      answers: ["Source Code Management with Git", "Branches"],
    },
    {
      id: 4,
      questions: "Jenkins",
      answers: [
        "What Is CI/CD",
        "Jenkins on Local",
        "Jenkins on AWS",
        "Jenkins as a container",
        "Jenkins and Github",
        "Your First Jenkins Project",
        "Jenkins Pipeline as a Code (Groovy Scripting)",
      ],
    },
    {
      id: 5,
      questions: "Python",
      answers: [
        "Strings",
        "Functions",
        "Loops",
        "If Statement",
        "Classes and Objects",
      ],
    },
    {
      id: 6,
      questions: "Ansible",
      answers: [
        "What is Ansible?",
        "Installing Ansible",
        "Adhoc Commands",
        "Ansible Playbooks",
        "Managing Software and Services",
        "Ansible Playbooks",
      ],
    },
    {
      id: 7,
      questions: "Kubernetes",
      answers: [
        "Introduction to Container Orchestration",
        "Kubernetes Architecture",
        "Minikube/Kops/KubeAdm for Kubernetes Cluster",
        "Minikube for Local",
        "Kops for AWS",
        "ConfigMaps & Secrets",
      ],
    },
    {
      id: 8,
      questions: "IaC with Terraform",
      answers: [
        "Infrastructure as Code Concepts",
        "Terraform Configuration Language",
        "State Management",
        "Terraform Commands",
        "Variables, Output & Provisioning",
      ],
    },
  ];

  const [selected, setSelected] = React.useState(null);

  function toggle(i) {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  }

  const item = Outline.map((item, i) => {
    return (
      <div key={i} className="item lg:w-full">
        <div
          className="duration-100 hover:bg-[#FDCA01] hover:text-black text-white title flex justify-between items-center cursor-pointer border rounded-lg border-[#FDCA01] py-3 px-5 mb-6"
          onClick={() => toggle(i)}
        >
          <h3 className="lg:text-[20px] text-[17px] md:w-auto w-[90%] font-medium">
            {item.questions}
          </h3>
          <div className="w-[20px]">
            <p className="text-[20px]">{selected === i ? "-" : "+"}</p>
          </div>
        </div>
        <div
          className={
            selected === i
              ? `content h-auto max-h-[999px] duration-300 py-5`
              : `content max-h-0 overflow-hidden duration-300`
          }
        >
          <ul className="text-white font-normal list-disc">
            {item.answers.map((item) => {
              return (
                <div
                  key={item.length * Math.random()}
                  className="border border-slate-600 py-2 hover:bg-gray-800 translate-y-[-20px]"
                >
                  <li className=" ml-[35px]">{item}</li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  });

  return (
    <section className="lg:px-[81px] lg:py-[130px] md:px-[30px] md:py-[150px] px-[20px] py-[160px]">
      <section className="text-white md:grid grid-cols-2 gap-x-6 md:pt-10 mb-20">
        <div className="text-[16px] text-[#A1A1AA] md:mb-0 mb-10">
          <p className="text-[#FDCA01] font-bold lg:text-[56px] md:text-[34px] text-[24px] mb-2">
            Curriculum
          </p>
          <p className="text-justify mb-5">
            Our DevOps curriculum is framed by industry experts, it will take
            you on a deep dive through the deployment pipeline to continuous
            integration. This course covers all the latest topics from basics to
            advanced level topics Linux, GIT, Ansible, AWS, and Jenkins.
          </p>
          <p className="lg:text-[20px] md:text-[18px] text-white mb-2">
            Course Features
          </p>
          <p className="flex mb-3">
            <img src={media} className="inline my-auto mr-2" alt="" />
            <span>Live Quizzes</span>
          </p>
          <p className="flex mb-3">
            <img src={video} className="inline my-auto mr-2" alt="" />
            <span>Virtual Led Classes</span>
          </p>
          <p className="flex mb-3">
            <img src={foundation} className="inline my-auto mr-2" alt="" />
            <span>Soft Skills and Agile Training</span>
          </p>
          <p className="flex mb-3">
            <img src={loc} className="inline my-auto mr-2" alt="" />
            <span>Hands-on Labs</span>
          </p>
        </div>
        <div className="lg:w-[350px] md:w-[300px] m-auto  rounded-lg overflow-hidden">
          <img className="w-full:" src={curriculum} alt="" />
        </div>
      </section>

      <section className="">
        <div className="">{item}</div>
      </section>
    </section>
  );
}
