import React from "react";

export default function Listing(props) {
  return (
    <div className="flex items-center md:space-x-2 text-left lg:mb-0 mb-3">
      <p className="leading-[32px] lg:font-normal  text-base text-[#B1B1B1]">
        {props.upper}
      </p>
      <p className="leading-[24px] lg:text-base text-sm font-medium tracking-[-0.16%] text-[#FDCA01]">
        {props.lower}
      </p>
    </div>
  );
}
